import {
  getLoggedInUserInfo,
  getUsersAsync,
  getUsersByTenantAsync,
} from "apis/UserApi";
import { UserListByTenant } from "store/slices/UserSlice";
import { RootState } from "store/store";
import { createAppAsyncThunk } from "store/ThunkUtility";

export const loadUsersAsync = createAppAsyncThunk(
  "users/loadUsers",
  async () => {
    return await getUsersAsync();
  }
);

export const loadUsersByTenantAsync = createAppAsyncThunk<
  any,
  { tenantId: number },
  { state: RootState }
>("users/loadUsersByTenantAsync", async (request, thunkAPI) => {
  const state = thunkAPI.getState();
  const existingUserListByTenantIndex = state.userData.usersByTenant.findIndex(
    (userList: UserListByTenant) => userList.tenantId === request.tenantId
  );

  if (existingUserListByTenantIndex > -1) {
    return state.userData.usersByTenant[existingUserListByTenantIndex].userList;
  } else {
    return await getUsersByTenantAsync(request.tenantId);
  }
});

export const loadLoggedInUserInfo = createAppAsyncThunk(
  "users/loadLoggedInUserInfo",
  async () => {
    return await getLoggedInUserInfo();
  }
);
