import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import PromineoCancelEditingConfirmationDialog from "components/common/controls/PromineoCancelEditingConfirmationDialog";
import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import PromineoTabPanel from "components/common/controls/PromineoTabPanel";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { Item } from "devextreme-react/tab-panel";
import useConfigMapapingAndValueTransformation from "hooks/DirectMappingAndValueTransformationHook";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";
import { EditConfigHeaderData } from "interfaces/component-data/EditConfigHeaderData";
import SafranStructureImportMappingConfig from "interfaces/host-system-config/safran/SafranStructureImportMappingConfig";
import ConfigUpdateRequest from "interfaces/request/ConfigUpdateRequest";
import ConfigDetailResponse from "interfaces/response/ConfigDetailResponse";
import FieldDetailsResponse from "interfaces/response/FieldDetailsResponse";
import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import LabelResponse from "interfaces/response/LabelResponse";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { CONFIGS } from "shared/constants/RoutePathConstants";
import { EditorMode } from "shared/enums/EditorMode";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { HostSystem } from "shared/enums/feature/HostSystem";
import { PlanningObjectTypes } from "shared/enums/feature/PlanningObjectTypes";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import { ValidationErrorType } from "shared/enums/ValidationErrorType";
import { SupportedHostSystemConfigurationType } from "shared/types/HostSystemConfigurationTypes";
import {
  checkIfHasDuplicates,
  checkIfResponseIsEqual,
  deepCopyObject,
  GetNewId,
} from "shared/utilities/CommonUtility";
import { getProcessedMappingForConfigUpdateRequest } from "shared/utilities/ConfigUtility";
import {
  getDefaultHostSystemConfigurationValue,
  getHostSystemConfigurationValue,
} from "shared/utilities/HostSystemConfigurationUtility";
import { toastError, toastSuccess } from "shared/utilities/ToastUtility";
import {
  makeConfigPublisedAsync,
  modifyConfigAsync,
  removeConfigAsync
} from "store/actions/ConfigActions";
import { loadConnectorDetailsAsync } from "store/actions/ConnectorActions";
import { loadFieldDetailsWithContentControlValuesByTemplateIdAsync } from "store/actions/FieldActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { resetSelectedConnector } from "store/slices/ConnectorSlice";
import HostSystemParameters from "../host-system/HostSystemParameters";
import ConfigScheduleEditor from "./config-schedule/ConfigScheduleEditor";
import ConfigValueTransformationEditor from "./config-value-transformation/ConfigValueTransformationEditor";
import ConfigValidationFailDialog from "./ConfigValidationFailDialog";
import DirectMappingEditor from "./direct-mapping/DirectMappingEditor";
import EditConfigHeader from "./edit-config-header/EditConfigHeader";

interface Props {
  isNew?: boolean;
  selectedTab?: number;
  config: ConfigDetailResponse;
}

const NUMBER_OF_TABS = 4;
enum ConfigTabEnum {
  "Schedule" = 0,
  "DirectMapping" = 1,
  "ValueTranformation" = 2,
  "HostSystemParameters" = 3,
}

type ConfigSaveResult = {
  isSuccessFul: boolean;
  resultConfig: ConfigDetailResponse | null;
  errors: any;
};

export default function ConfigEditor(props: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const shouldDisplayConnectorChangeWarning = useRef(false);
  const shouldDisplayTemplateChangeWarning = useRef(false);
  const [selectedTab, setSelectedTab] = useState<ConfigTabEnum>(
    props.selectedTab && props.selectedTab < NUMBER_OF_TABS
      ? props.selectedTab
      : ConfigTabEnum.Schedule
  );
  const [selectedScheduleIds, setSelectedScheduleIds] = useState<number[]>([]);
  const [selectedCodeSet, setSelectedCodeSet] = useState<number | null>(null);
  const [configHeaderData, setConfigHeaderData] =
    useState<EditConfigHeaderData>({ ...props.config } as EditConfigHeaderData);
  const [isModified, setIsModified] = useState(false);
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] =
    useState(false);
  const [
    configSaveOrPublishFailureDialogValidation,
    setConfigSaveOrPublishFailureDialogValidation,
  ] = useState<string[]>([]);
  const [
    configSaveOrPublishFailureDialogTitle,
    setConfigSaveOrPublishFailureDialogTitle,
  ] = useState<string>("");
  const [
    isUnsavedChangeConfirmationVisible,
    setUnsavedChangeConfirmationVisible,
  ] = useState(false);
  const [
    isPublishConfigConfirmationDialogVisible,
    setIsPublishConfigConfirmationDialogVisible,
  ] = useState(false);
  const [configValidationWarning, setConfigValidationWarning] = useState<
    string[]
  >([]);
  const [connectorToChange, setConnectorToChange] = useState<{
    connectorId: number;
    connectorName: string;
  } | null>(null);
  const [templateToChange, setTemplateToChange] = useState<{
    templateId: number;
    templateName: string;
  } | null>(null);
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);
  const [anyScheduleSelected, setAnyScheduleSelected] =
    useState<boolean>(false);
  const [hostSystemConfiguration, setHostSystemConfiguration] =
    useState<SupportedHostSystemConfigurationType>();
  const [configDirectMappings, setConfigDirectMappings] = useState<
    FieldMappingResponse[]
  >([]);
  const [configValueTransformation, setConfigValueTransformation] = useState<
    FieldMappingResponse[]
  >([]);
  const [configDirection, setConfigDirection] = useState<ConfigDirection>(
    props.config.direction
  );

  const scheduleEditorRef = useRef<any>();
  const isTemplateChanged = useRef<boolean>(false);
  const isConfigDirectionChanged = useRef<boolean>(false);
  const isConnectorChanged = useRef<boolean>(false);
  const isCodeSetChanged = useRef<boolean>(false);
  const hostParameterGridRef = useRef<any>();

  const loadConnectorDetails = (connectorId: number) => {
    displayLoadingPanel();
    dispatch(loadConnectorDetailsAsync(connectorId)).finally(hideLoadingPanel);
  };

  const selectedConnectorDetail = useAppSelector(
    (state) => state.connectorData.selectedConnector
  );

  const loadTemlpateFieldsDetailsWithContentControlByTemplateId = (
    templateId: number
  ) => {
    displayLoadingPanel();
    dispatch(
      loadFieldDetailsWithContentControlValuesByTemplateIdAsync(templateId)
    ).finally(hideLoadingPanel);
  };

  const templateFieldsDetailsWithContentControl = useAppSelector(
    (state) => state.fieldData.fieldsWithContentControlDetails
  );

  useEffect(() => {
    return () => {
      dispatch(resetSelectedConnector());
    };
  }, []);

  const onConfigSaveOrPublishFailureDialogClose = () => {
    setConfigSaveOrPublishFailureDialogValidation([]);
    setConfigSaveOrPublishFailureDialogTitle("");
  };

  const handleHostSystemConfigurationValueChanged = (
    fieldName: string,
    value: any
  ) => {
    if (hostSystemConfiguration) {
      setHostSystemConfiguration((previousConfiguration) => ({
        ...previousConfiguration!,
        [fieldName]: value,
      }));
    }
  };

  const changeModifyState = (isModified: boolean) => {
    setIsModified(isModified);
  };

  const setHostSystemConfigurationByDirectionAndHostSystem = (
    direction: ConfigDirection,
    hostSystem: HostSystem,
    hostSystemParameters: string
  ) => {
    let hostSystemConfiguraion = getHostSystemConfigurationValue(
      hostSystem,
      direction,
      hostSystemParameters
    );

    if (hostSystemConfiguraion) {
      setHostSystemConfiguration(hostSystemConfiguraion);
    }
  };

  const setDefaultHostSystemConfigurationByDirectionAndHostSystem = (
    direction: ConfigDirection,
    hostSystem: HostSystem
  ) => {
    let hostSystemConfiguraion = getDefaultHostSystemConfigurationValue(
      hostSystem,
      direction
    );

    if (hostSystemConfiguraion) {
      setHostSystemConfiguration(hostSystemConfiguraion);
    }
  };

  useEffect(() => {
    let isDisabled = true;
    if (
      configHeaderData &&
      configHeaderData.title &&
      configHeaderData.connectorId &&
      configHeaderData.templateId
    ) {
      // For SAP, schedule selection is not required.
      if (selectedConnectorDetail?.hostSystem === HostSystem.SAP) {
        isDisabled = false;
      } else {
        isDisabled = !anyScheduleSelected;
      }
    }

    setDisableSaveButton(isDisabled);
  }, [configHeaderData, anyScheduleSelected, selectedConnectorDetail]);

  useEffect(() => {
    if (props.config) {
      if (props.config.connectorId) {
        shouldDisplayConnectorChangeWarning.current = true;
      }

      if (props.config.templateId) {
        shouldDisplayTemplateChangeWarning.current = true;
      }

      if (
        props.config.connectorId &&
        selectedConnectorDetail?.id !== props.config.connectorId
      ) {
        loadConnectorDetails(props.config.connectorId);
      }

      if (props.config.hostSystemParameters) {
        setHostSystemConfigurationByDirectionAndHostSystem(
          props.config.direction,
          props.config.connectorHostSystem,
          props.config.hostSystemParameters
        );
      } else {
        setDefaultHostSystemConfigurationByDirectionAndHostSystem(
          props.config.direction,
          props.config.connectorHostSystem
        );
      }

      let selectedIds = props.config.schedules.map((sc) => sc.id);
      setSelectedScheduleIds(selectedIds);
      setAnyScheduleSelected(selectedIds.length !== 0);
      let selectedCodeSetId =
        props.config.schedules && props.config.schedules.length !== 0
          ? props.config.schedules[0].userFieldSetId
          : null;
      setSelectedCodeSet(selectedCodeSetId ?? null);
    }
  }, [props.config]);

  const getDirectMappingListFromFieldDetailResponse = (
    configFields: FieldDetailsResponse[]
  ) => {
    const directMappings = configFields.map<FieldMappingResponse>((f) => {
      let mapping: FieldMappingResponse = {
        id: 0,
        _key_: GetNewId(),
        allowBlank: f.allowBlank ?? true,
        allowContentControl: f.allowContentControl ?? false,
        configId: props.config.id,
        dataType: f.dataType,
        dataTypeText: f.dataTypeText,
        description: f.description,
        formula: "",
        fieldId: f.id,
        hasDirectMapping: true,
        mappedConnectorHostFieldId: 0,
        mappedConnectorHostFieldName: "",
        name: f.name,
        planningObjectType: f.planningObjectType,
        planningObjectTypeText: f.planningObjectTypeText,
        valueMaps: [],
        isConfigTemplateFieldMapping: true,
        isCoreFieldMapping: false,
        contentControlLevelText: f.contentControlLevelText ?? "",
        values: deepCopyObject(f.values),
      };

      return mapping;
    });

    return directMappings;
  };

  const decorateFieldMappingsWithDefaultHostFields = (
    mappings: FieldMappingResponse[]
  ): FieldMappingResponse[] => {
    const getPreSelectedConnectorHostField = (
      fieldName: string,
      planningObjectType: PlanningObjectTypes
    ) => {
      return selectedConnectorDetail?.hostFields?.find(
        (hf) =>
          hf.planningObjectType === planningObjectType &&
          hf.name == fieldName &&
          (!hf.userFieldSetId ||
            (selectedCodeSet && selectedCodeSet === hf.userFieldSetId))
      );
    };

    return mappings.map((dm) => {
      const preSelectedConnectorHostField = getPreSelectedConnectorHostField(
        dm.name,
        dm.planningObjectType
      );
      return {
        ...dm,
        mappedConnectorHostFieldId: preSelectedConnectorHostField?.id ?? 0,
        mappedConnectorHostFieldName: preSelectedConnectorHostField?.name ?? "",
      };
    });
  };

  const preSelectHostFieldsForMapping = (
    directMappings: FieldMappingResponse[],
    connectorHostSystem: HostSystem,
    direction: ConfigDirection
  ) => {
    let allowPreSelectionOfHostFields =
      (connectorHostSystem === HostSystem.SAP ||
        connectorHostSystem === HostSystem.IlapAnalytics) &&
      direction == ConfigDirection.Receiving &&
      selectedCodeSet &&
      directMappings.filter(
        (m) => m.hasDirectMapping && !m.mappedConnectorHostFieldId
      ).length === directMappings.length;

    if (allowPreSelectionOfHostFields) {
      directMappings =
        decorateFieldMappingsWithDefaultHostFields(directMappings);
    }
    return directMappings;
  };

  const getMappingDataFromConfig = () => {
    let directMappings = props.config.mappings.filter(
      (m) => !m.isCoreFieldMapping
    );
    let valueTransformations = props.config.mappings.filter(
      (m) => !m.hasDirectMapping || m.isCoreFieldMapping
    );

    // find core field mapping that are added and are in cache
    // else might get deleted when changing connector
    let newAddedCoreFieldMapping = updatedValueTransformations.filter(
      (m) =>
        m.isCoreFieldMapping &&
        !valueTransformations.find(
          (v) => v.isCoreFieldMapping && v.coreFieldId === m.coreFieldId
        )
    );

    valueTransformations = valueTransformations.concat(
      newAddedCoreFieldMapping
    );

    return { directMappings, valueTransformations };
  };

  const setDirectMappingAndValuetransformationWithPreSelection = (
    directMappings: FieldMappingResponse[],
    valueTransformations: FieldMappingResponse[]
  ) => {
    if (selectedConnectorDetail) {
      directMappings = preSelectHostFieldsForMapping(
        directMappings,
        selectedConnectorDetail.hostSystem,
        configDirection
      );
    }
    setConfigDirectMappings(directMappings);
    setConfigValueTransformation(valueTransformations);
  };

  useEffect(() => {
    // set mapping data after initial load of config
    if (!isConnectorChanged.current && selectedConnectorDetail) {
      let { directMappings, valueTransformations } = getMappingDataFromConfig();
      setDirectMappingAndValuetransformationWithPreSelection(
        directMappings,
        valueTransformations
      );
    }
  }, [props.config, selectedConnectorDetail]);

  useEffect(() => {
    // reassign all fields when template changes keeping all corefields mapping from cache
    if (isTemplateChanged.current === true) {
      let directMappings: FieldMappingResponse[] = [];
      let valueTransformations: FieldMappingResponse[] = [];

      if (props.config.templateId === configHeaderData.templateId) {
        ({ directMappings, valueTransformations } = getMappingDataFromConfig());
      } else {
        directMappings = getDirectMappingListFromFieldDetailResponse(
          templateFieldsDetailsWithContentControl
        );
        valueTransformations = updatedValueTransformations
          .filter((m) => m.isCoreFieldMapping)
          .map((m) => {
            let mapping: FieldMappingResponse = deepCopyObject(m);
            mapping.id = 0;
            return mapping;
          });
      }

      setDirectMappingAndValuetransformationWithPreSelection(
        directMappings,
        valueTransformations
      );
      isTemplateChanged.current = false;
    }
  }, [templateFieldsDetailsWithContentControl]);

  const resetAndPreSelectHostFieldsForMapping = () => {
    let directMappings: FieldMappingResponse[] = deepCopyObject(
      updatedDirectMappings
    );
    let valueTransformations: FieldMappingResponse[] = deepCopyObject(
      updatedValueTransformations
    );

    directMappings = directMappings.map((fm) => {
      fm.mappedConnectorHostFieldId = null;
      fm.mappedConnectorHostFieldName = "";
      return fm;
    });

    valueTransformations = valueTransformations.map((fm) => {
      fm.mappedConnectorHostFieldId = null;
      fm.mappedConnectorHostFieldName = "";
      fm.formula = "";
      return fm;
    });

    setDirectMappingAndValuetransformationWithPreSelection(
      directMappings,
      valueTransformations
    );
  };

  const setHostSystemConfigurationOnConnectorOrDirectionChange = () => {
    if (selectedConnectorDetail) {
      if (
        selectedConnectorDetail.hostSystem ===
          props.config.connectorHostSystem &&
        configDirection === props.config.direction &&
        props.config.hostSystemParameters
      ) {
        setHostSystemConfigurationByDirectionAndHostSystem(
          configDirection,
          selectedConnectorDetail.hostSystem,
          props.config.hostSystemParameters
        );
      } else {
        setDefaultHostSystemConfigurationByDirectionAndHostSystem(
          configDirection,
          selectedConnectorDetail.hostSystem
        );
      }
    }
  };

  useEffect(() => {
    if (isConnectorChanged.current) {
      resetAndPreSelectHostFieldsForMapping();
      setHostSystemConfigurationOnConnectorOrDirectionChange();
      isConnectorChanged.current = false;
    }
  }, [selectedConnectorDetail]);

  useEffect(() => {
    if (isCodeSetChanged.current) {
      resetAndPreSelectHostFieldsForMapping();
      isCodeSetChanged.current = false;
    }
  }, [selectedCodeSet]);

  useEffect(() => {
    if (isConfigDirectionChanged.current) {
      let directMappings: FieldMappingResponse[] = deepCopyObject(
        updatedDirectMappings
      );
      let valueTransformations: FieldMappingResponse[] = deepCopyObject(
        updatedValueTransformations
      );
      setDirectMappingAndValuetransformationWithPreSelection(
        directMappings,
        valueTransformations
      );
      setHostSystemConfigurationOnConnectorOrDirectionChange();
      isConfigDirectionChanged.current = false;
    }
  }, [configDirection]);

  const handleTitleChange = useCallback((value: any) => {
    setConfigHeaderData((prev) => {
      return { ...prev, title: value.value };
    });
    changeModifyState(true);
  }, []);

  const handleDeleteLabel = useCallback((label: LabelResponse) => {
    setConfigHeaderData((prev) => {
      return { ...prev, labels: prev.labels.filter((l) => l.id !== label.id) };
    });
    changeModifyState(true);
  }, []);

  const handleNewLabelCreationToAddInTemplate = useCallback(
    (label: LabelResponse) => {
      setConfigHeaderData((prev) => {
        return { ...prev, labels: [...prev.labels, label] };
      });
      changeModifyState(true);
    },
    []
  );

  const handleDeleteConfigClick = () => {
    setIsDeleteConfirmationVisible(true);
  };

  const handleConfigCreateUpdateOrPublishErrorResponse = (
    error: any,
    onBusinessRuleValidationError: any
  ) => {
    if (
      error.statusCode !== 400 ||
      !error.errorsWithType ||
      error.errorsWithType.length === 0
    ) {
      toastError(error.message);
    } else {
      let businessRuleValidationErrors: string[] = [];
      let otherErrors: string[] = [];
      error.errorsWithType.forEach(
        (e: { type: ValidationErrorType; message: string }) => {
          if (e.type === ValidationErrorType.BusinessRuleValidationError) {
            businessRuleValidationErrors.push(e.message);
          } else {
            otherErrors.push(e.message);
          }
        }
      );
      if (otherErrors.length !== 0) {
        toastError(otherErrors.join(","));
      }
      if (
        businessRuleValidationErrors.length !== 0 &&
        businessRuleValidationErrors
      ) {
        onBusinessRuleValidationError(businessRuleValidationErrors);
      }
    }
  };

  const validateAndGetErrorMessagesForBlankHostFields = () => {
    const fieldNameForNotAllowingBlankHostFields: string[] = [];
    if (updatedValueTransformations && updatedDirectMappings) {
      updatedDirectMappings.forEach((mapping) => {
        if (!mapping.allowBlank) {
          if (mapping.hasDirectMapping) {
            if (!mapping.mappedConnectorHostFieldId) {
              fieldNameForNotAllowingBlankHostFields.push(mapping.name);
            }
          } else {
            // CoreFields will automatically be excluded as configDirectMappings does not contain any core fields
            const templateFieldMapping = updatedValueTransformations.find((m) =>
              checkIfResponseIsEqual(m, mapping)
            );
            if (
              !templateFieldMapping?.formula &&
              !templateFieldMapping?.mappedConnectorHostFieldId
            ) {
              fieldNameForNotAllowingBlankHostFields.push(mapping.name);
            }
          }
        }
      });
    }
    return fieldNameForNotAllowingBlankHostFields;
  };

  const navigateForCancellation = () => {
    let navigationPath = props.isNew
      ? CONFIGS
      : `/setup/configs/${props.config.id}`;
    navigate(navigationPath);
  };

  const handleCancelClick = useCallback(() => {
    if (isModified) {
      setUnsavedChangeConfirmationVisible(true);
    } else {
      navigateForCancellation();
    }
  }, [isModified]);

  const handleCancelClosing = () => {
    setUnsavedChangeConfirmationVisible(false);
  };

  const handleConfirmClose = () => {
    setUnsavedChangeConfirmationVisible(false);
    navigateForCancellation();
  };

  const handleConfirmDelete = () => {
    setIsDeleteConfirmationVisible(false);
    displayLoadingPanel();
    dispatch(removeConfigAsync(props.config.id))
      .then(() => {
        toastSuccess("Deleted Config successfully.");
        navigate(CONFIGS);
      })
      .finally(hideLoadingPanel);
  };

  const handleCancelDelete = () => {
    setIsDeleteConfirmationVisible(false);
  };

  const resetScheduleSelectionOnConnectorChange = () => {
    setSelectedCodeSet(null);
    setSelectedScheduleIds([]);
    setAnyScheduleSelected(false);
    scheduleEditorRef?.current?.resetGridSelection();
  };

  // During creation we don't want to see the connector change warning
  // warning should be shown when changing an exsiting one
  const handleConnectorSelectionChange = useCallback(
    (connectorId: number, connectorName: string) => {
      if (shouldDisplayConnectorChangeWarning.current) {
        setConnectorToChange({ connectorId, connectorName });
      } else {
        changeConnectorSelection(connectorId, connectorName);
      }
    },
    []
  );

  const handleConfirmConnectorSelectionChange = () => {
    if (connectorToChange) {
      changeConnectorSelection(
        connectorToChange.connectorId,
        connectorToChange.connectorName
      );
    }
  };

  const changeConnectorSelection = (
    connectorId: number,
    connectorName: string
  ) => {
    loadConnectorDetails(connectorId);
    isConnectorChanged.current = true;
    setConfigHeaderData((prev) => {
      shouldDisplayConnectorChangeWarning.current = true;
      return prev
        ? {
            ...deepCopyObject(prev),
            connectorId: connectorId,
            connectorName: connectorName,
          }
        : undefined;
    });

    setConnectorToChange(null);
    changeModifyState(true);
    resetScheduleSelectionOnConnectorChange();
  };

  const handleTemplateSelectionChange = useCallback(
    (templateId: number, templateName: string) => {
      if (shouldDisplayTemplateChangeWarning.current) {
        setTemplateToChange({ templateId, templateName });
      } else {
        changeTemplateSelection(templateId, templateName);
      }
    },
    []
  );

  const handleConfirmTemplateSelectionChange = () => {
    if (templateToChange) {
      changeTemplateSelection(
        templateToChange.templateId,
        templateToChange.templateName
      );
    }
  };

  const changeTemplateSelection = (templateId: number, title: string) => {
    setConfigHeaderData((prev) => {
      return {
        ...prev,
        templateId: templateId,
        templateTitle: title,
      };
    });
    isTemplateChanged.current = true;
    loadTemlpateFieldsDetailsWithContentControlByTemplateId(templateId);
    changeModifyState(true);
    setTemplateToChange(null);
    shouldDisplayTemplateChangeWarning.current = true;
  };

  const handleConfigDirectionChange = useCallback(
    (direction: ConfigDirection) => {
      setConfigHeaderData((prev) => {
        return {
          ...prev,
          direction: direction,
          directionText: ConfigDirection[direction],
        };
      });
      setConfigDirection(direction);
      changeModifyState(true);
      isConfigDirectionChanged.current = true;
    },
    []
  );

  const handleScheduleGridSelectionChange = useCallback(
    (selectedSchedulesCount: number) => {
      setAnyScheduleSelected(selectedSchedulesCount !== 0);
      changeModifyState(true);
    },
    []
  );

  const handleCodeSetSelectionChange = useCallback((codeSet: number | null) => {
    isCodeSetChanged.current = true;
    setSelectedCodeSet(codeSet);
  }, []);

  const headerDivId: string = "config-edit-header";
  const excludedContainerIds: string[] = [headerDivId];
  const gridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 190,
  });

  const {
    configDirectMappings: updatedDirectMappings,
    configValueTransformations: updatedValueTransformations,
    directMappingEditorProps,
    valueTransformationGridProps,
  } = useConfigMapapingAndValueTransformation({
    isEdit: true,
    configDirection: configDirection,
    height: gridHeight,
    initialDirectMappings: configDirectMappings,
    initialValueTranformations: configValueTransformation,
    selectedCodeSet: selectedCodeSet,
  });

  const handleSaveChanges = useCallback(
    (showWarningDialog = true) => {
      const checkIfUpdateRequestIsValid = () => {
        const errorMessages = [];
        if (selectedConnectorDetail?.hostSystem === HostSystem.Safran) {
          if (!!hostParameterGridRef?.current?.getStructureMappings) {
            const structureMappings =
              hostParameterGridRef.current.getStructureMappings() as SafranStructureImportMappingConfig[];
            if (structureMappings?.length) {
              const isValid = structureMappings.every(
                (mapping) =>
                  mapping.rFieldName?.trim() && mapping.structureName?.trim()
              );
              if (!isValid) {
                errorMessages.push(
                  "Mapping must have Structure name and R-Field name."
                );
              }
              if (
                checkIfHasDuplicates(
                  structureMappings,
                  (obj) => `${obj.structureName}-${obj.rFieldName}`
                )
              ) {
                errorMessages.push(
                  "Duplicate mapping is not allowed (Same structure mapped to same R field multiple times)."
                );
              }
            }
          }
        }
        if (errorMessages.length) {
          toastError(errorMessages.join("\n"));
          return false;
        }
        return true;
      };

      const getHostSystemParameters = () => {
        if (hostParameterGridRef?.current) {
          if (selectedConnectorDetail?.hostSystem === HostSystem.SAP) {
            if (hostParameterGridRef.current.getUpdateSapHostParameters) {
              let hostParameters =
                hostParameterGridRef.current.getUpdateSapHostParameters();
              return JSON.stringify(hostParameters);
            }
          } else if (
            selectedConnectorDetail?.hostSystem === HostSystem.Safran
          ) {
            if (hostParameterGridRef?.current?.getStructureMappings) {
              let safranHostParameters = {
                ...hostSystemConfiguration,
                structureImportMappings:
                  hostParameterGridRef.current.getStructureMappings(),
              };
              return JSON.stringify(safranHostParameters);
            }
          }
        }

        return hostSystemConfiguration
          ? JSON.stringify(hostSystemConfiguration)
          : "";
      };

      if (disableSaveButton || !configHeaderData) {
        return;
      }

      // in new IEA UI after pressing "save and next", we are sent to the edit config ui's 2nd tab
      // there the first tab has not been rendered yet, and so scheduleEditorRef would be null.
      // in that case we will be using the selectedScheduleIds that are available in the config.
      // if the user changed the selected schedules, then he has went to the first tab.
      // in that case scheduleEditorRef wont be null and it will replace the selectedScheduleIds from the config
      // with the new one's that the user will provide
      let hostScheduleIdentifiers: number[] = selectedScheduleIds;

      if (selectedConnectorDetail?.hostSystem === HostSystem.SAP) {
        if (selectedConnectorDetail.hostSchedules.length) {
          // It is ensured that the connector has one host schedule.
          if (hostScheduleIdentifiers.length === 0) {
            hostScheduleIdentifiers.push(
              selectedConnectorDetail.hostSchedules[0].id
            );
          }
        }
      } else if (scheduleEditorRef?.current?.getSelectedRows) {
        hostScheduleIdentifiers =
          scheduleEditorRef.current.getSelectedRows() as number[];
      }

      let mappings = getProcessedMappingForConfigUpdateRequest(
        updatedDirectMappings,
        updatedValueTransformations,
        configHeaderData.direction
      );

        mappings.forEach((m) => {
          m.valueMaps = m.valueMaps.filter((v) => v.value && v.value.trim());
        });

      const configUpdateRequest: ConfigUpdateRequest = {
        connectorHostScheduleIdentifiers: hostScheduleIdentifiers,
        connectorId: configHeaderData.connectorId,
        direction: configHeaderData.direction,
        labelIdentifiers: configHeaderData.labels.map((l) => l.id),
        templateId: configHeaderData.templateId,
        title: configHeaderData.title,
        mappings: mappings,
        hostSystemParameters: getHostSystemParameters(),
      };

      if (!checkIfUpdateRequestIsValid()) {
        return;
      }

      if (showWarningDialog) {
        const fieldNameForNotAllowingBlankHostFields =
          validateAndGetErrorMessagesForBlankHostFields();
        if (fieldNameForNotAllowingBlankHostFields.length !== 0) {
          const fieldNameForNotAllowingBlankHostFieldsString =
            fieldNameForNotAllowingBlankHostFields
              .map((name) => `'${name}'`)
              .join(", ");
          const errorMessagesForBlankHostFields = `Template field(s) ${fieldNameForNotAllowingBlankHostFieldsString} does(do) not accept blank values, so must have a host field assigned to it(them). You will not be able to publish this config until fixed.`;
          setConfigValidationWarning([errorMessagesForBlankHostFields]);
        }
      }

        displayLoadingPanel();
        return dispatch(
          modifyConfigAsync({
            configId: props.config.id,
            config: configUpdateRequest,
          })
        )
          .unwrap()
          .then((updatedConfig) => {
            toastSuccess("Updated Config Successfully.");
            changeModifyState(false);
            return {
              isSuccessFul: true,
              resultConfig: updatedConfig,
              errors: null,
            } as ConfigSaveResult;
          })
          .catch((errors: any) => {
            toastError("Config Could Not Be Updated");
            return {
              isSuccessFul: false,
              resultConfig: null,
              errors: errors,
            } as ConfigSaveResult;
          })
          .finally(hideLoadingPanel);
      },
    [
      props.config,
      configHeaderData,
      updatedDirectMappings,
      updatedValueTransformations,
      scheduleEditorRef,
      disableSaveButton,
      hostSystemConfiguration,
      selectedConnectorDetail,
    ]
  );

  const onSaveOrPublishError = (errors: any, errorTitle: string) => {
    handleConfigCreateUpdateOrPublishErrorResponse(errors, (errors: any) => {
      if (errors) {
        setConfigSaveOrPublishFailureDialogTitle(errorTitle);
        setConfigSaveOrPublishFailureDialogValidation(errors);
      }
    });
  };

  const handleSaveChangesClick = useCallback(async () => {
    if (disableSaveButton) {
      return;
    }

    const saveChangesResult = await handleSaveChanges();
    if (saveChangesResult) {
      if (saveChangesResult.isSuccessFul) {
        if (props.isNew) {
          // INFO: When creating a config, allow pre-selection of the host fields for specific host systems
          navigate(`/setup/configs/${saveChangesResult.resultConfig?.id}/edit`);
        }
      } else {
        let errorTitle = props.isNew
          ? "Config cannot be created"
          : "Config cannot be saved";
        onSaveOrPublishError(saveChangesResult.errors, errorTitle);
      }
    }
  }, [handleSaveChanges, disableSaveButton]);

  const handleSaveAndNextClick = useCallback(async () => {
    if (disableSaveButton) {
      return;
    }

    const saveChangesResult = await handleSaveChanges();
    if (saveChangesResult) {
      if (saveChangesResult.isSuccessFul) {
        if (props.isNew) {
          // INFO: When creating a config, allow pre-selection of the host fields for specific host systems
          navigate(
            `/setup/configs/${saveChangesResult.resultConfig?.id}/edit`,
            { state: { selectedTab: (selectedTab + 1) % NUMBER_OF_TABS } }
          );
        } else {
          setSelectedTab((prev) => (prev + 1) % NUMBER_OF_TABS);
        }
      } else {
        let errorTitle = props.isNew
          ? "Config cannot be created"
          : "Config cannot be saved";
        onSaveOrPublishError(saveChangesResult.errors, errorTitle);
      }
    }
  }, [handleSaveChanges, disableSaveButton]);

  const handleSaveAndPublishClick = () => {
    if (props.config.isPublishedPreviously) {
      setIsPublishConfigConfirmationDialogVisible(true);
    } else {
      handleConfirmSaveAndPublishClick();
    }
  };

  const handleCancelSaveAndPublishClick = () => {
    setIsPublishConfigConfirmationDialogVisible(false);
  };

  const handleConfirmSaveAndPublishClick = useCallback(async () => {
    setIsPublishConfigConfirmationDialogVisible(false);
    if (disableSaveButton) {
      return;
    }

    const saveChangesResult = await handleSaveChanges(false);
    if (saveChangesResult) {
      if (saveChangesResult.isSuccessFul) {
        displayLoadingPanel();
        dispatch(makeConfigPublisedAsync(props.config.id))
          .unwrap()
          .then(() => {
            navigate(CONFIGS);
            toastSuccess("Published Config Successfully.");
          })
          .catch((errors: any) => {
            onSaveOrPublishError(errors, "Config cannot be published");
            toastError("Config Could Not Be Published");
          })
          .finally(hideLoadingPanel);
      } else {
        let errorTitle = props.isNew
          ? "Config cannot be created"
          : "Config cannot be saved";
        onSaveOrPublishError(saveChangesResult.errors, errorTitle);
      }
    }
  }, [handleSaveChanges, disableSaveButton]);

  if (!props.config) {
    return <></>;
  }

  return (
    <>
      <div id={headerDivId}>
        <EditConfigHeader
          data={configHeaderData}
          onTitleChanged={handleTitleChange}
          onDeleteLabel={handleDeleteLabel}
          onAddNewLabel={handleNewLabelCreationToAddInTemplate}
          onConnectorSelectionChange={handleConnectorSelectionChange}
          onTemplateSelectionChange={handleTemplateSelectionChange}
          onDirectionChange={handleConfigDirectionChange}
          readonly={selectedTab !== ConfigTabEnum.Schedule}
          isNew={props.isNew}
        />
      </div>

      <div className="mt-4">
        <PromineoTabPanel
          onSelectedIndexChange={setSelectedTab}
          selectedIndex={selectedTab}
        >
          <Item title={"Schedule selection"}>
            <div className="p-4">
              <ConfigScheduleEditor
                height={gridHeight}
                ref={scheduleEditorRef}
                selectedScheduleIds={selectedScheduleIds}
                connectorSchedules={
                  selectedConnectorDetail?.hostSystem !== HostSystem.SAP
                    ? selectedConnectorDetail?.hostSchedules ?? []
                    : []
                }
                onSelectionChanged={handleScheduleGridSelectionChange}
                onCodeSetSelectionChange={handleCodeSetSelectionChange}
                gridInfoText={
                  selectedConnectorDetail?.hostSystem === HostSystem.SAP
                    ? "Schedule selection not required"
                    : undefined
                }
              />
            </div>
          </Item>
          <Item title={"Direct mapping"}>
            <div className="p-4">
              <DirectMappingEditor
                {...directMappingEditorProps}
                isEdit={true}
              />
            </div>
          </Item>
          <Item title={"Value transformations"}>
            <div className="p-4">
              <ConfigValueTransformationEditor
                {...valueTransformationGridProps}
              />
            </div>
          </Item>
          <Item title={"Host system parameters"}>
            {selectedConnectorDetail ? (
              <div className="p-4">
                <HostSystemParameters
                  height={gridHeight}
                  mode={EditorMode.Edit}
                  direction={configDirection}
                  hostSystem={selectedConnectorDetail.hostSystem}
                  hostSystemConfiguration={hostSystemConfiguration}
                  selectedConnectorId={selectedConnectorDetail.id}
                  selectedCodeSet={selectedCodeSet}
                  handleConfigurationValueChanged={
                    handleHostSystemConfigurationValueChanged
                  }
                  ref={hostParameterGridRef}
                />
              </div>
            ) : null}
          </Item>
        </PromineoTabPanel>
      </div>

      <div className="flex justify-between mt-4 px-4">
        <div className="flex">
          <PromineoButton
            variant={PromineoButtonType.Secondary}
            text="Close"
            onClick={handleCancelClick}
          />
          <PromineoButton
            variant={PromineoButtonType.BorderlessDanger}
            text="Delete config"
            onClick={handleDeleteConfigClick}
          />
        </div>

        <div className="flex space-x-2">
          <PromineoButton
            variant={PromineoButtonType.Borderless}
            text="Save"
            onClick={handleSaveChangesClick}
            disabled={disableSaveButton}
          />
          {selectedTab === ConfigTabEnum.HostSystemParameters ? (
            <PromineoButton
              variant={PromineoButtonType.Primary}
              text="Save & publish"
              onClick={handleSaveAndPublishClick}
              disabled={disableSaveButton}
            />
          ) : (
            <PromineoButton
              variant={PromineoButtonType.Primary}
              text="Save & next"
              onClick={handleSaveAndNextClick}
              disabled={disableSaveButton}
            />
          )}
        </div>
      </div>

      {isDeleteConfirmationVisible ? (
        <PromineoConfirmationDialog
          actionOptions={{
            mode: PromineoModalMode.Modify,
          }}
          content={`Are you sure you want to delete this config?`}
          subContent="This action is irreversible and all information will be lost."
          isDeleteConfirm={true}
          confirmButtonText="Delete"
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        ></PromineoConfirmationDialog>
      ) : (
        <></>
      )}

      {isUnsavedChangeConfirmationVisible ? (
        <PromineoCancelEditingConfirmationDialog
          onConfirm={handleConfirmClose}
          onCancel={handleCancelClosing}
        ></PromineoCancelEditingConfirmationDialog>
      ) : (
        <></>
      )}

      {connectorToChange ? (
        <PromineoConfirmationDialog
          minWidth="450"
          width="auto"
          content={`Are you sure you want to change the connector?`}
          subContent="Changing connector will clear the host field selections."
          onConfirm={handleConfirmConnectorSelectionChange}
          onCancel={() => setConnectorToChange(null)}
          confirmButtonText="Confirm"
        ></PromineoConfirmationDialog>
      ) : (
        <></>
      )}

      {templateToChange ? (
        <PromineoConfirmationDialog
          minWidth="450"
          width="auto"
          content={`Are you sure you want to change the template?`}
          subContent="Changing template will clear the direct mappings"
          onConfirm={handleConfirmTemplateSelectionChange}
          onCancel={() => setTemplateToChange(null)}
          confirmButtonText="Confirm"
        ></PromineoConfirmationDialog>
      ) : (
        <></>
      )}

      {configValidationWarning.length !== 0 && (
        <ConfigValidationFailDialog
          title={"Warning"}
          onCancel={() => setConfigValidationWarning([])}
          validationFails={configValidationWarning}
        />
      )}

      {configSaveOrPublishFailureDialogValidation.length !== 0 && (
        <ConfigValidationFailDialog
          title={configSaveOrPublishFailureDialogTitle}
          onCancel={onConfigSaveOrPublishFailureDialogClose}
          validationFails={configSaveOrPublishFailureDialogValidation}
        />
      )}

      {isPublishConfigConfirmationDialogVisible && (
        <PromineoConfirmationDialog
          onConfirm={handleConfirmSaveAndPublishClick}
          onCancel={handleCancelSaveAndPublishClick}
          content="Are you sure you want to save and publish this config?"
          subContent={`This action will trigger an update to all the IEAs that use this config (with exception of title, label and host system parameter changes).`}
          cancelButtonText="Cancel"
          confirmButtonText="Save & publish"
        />
      )}
    </>
  );
}
