import { getCoreFieldsAsync } from "apis/CoreFieldApi";
import { RootState } from "store/store";
import { createAppAsyncThunk } from "store/ThunkUtility";

export const loadCoreFieldsAsync = createAppAsyncThunk<
  any,
  void,
  { state: RootState }
>("coreFields/loadCoreFields", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const coreFields = state.coreFieldData.coreFields;

  if (coreFields.length === 0) {
    return await getCoreFieldsAsync();
  } else {
    return coreFields;
  }
});
