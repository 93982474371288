import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import ConfigForExchangeAgreementResponse from "interfaces/response/ConfigForExchangeAgreementResponse";
import ConfigResponse from "interfaces/response/ConfigResponse";
import ConnectorScheduleSimplifiedResponse from "interfaces/response/ConnectorScheduleSimplifiedResponse";
import MySelfResponse from "interfaces/response/MySelfResponse";
import UserSimplifiedResponse from "interfaces/response/UserSimplifiedResponse";
import ConnectorBaseResponse from "interfaces/response/connector/ConnectorBaseResponse";
import { useEffect, useState } from "react";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { ExchangeRoleEnum } from "shared/enums/feature/ExchangeRoleEnum";
import { getDisplayExpressionForScheduleSelectorDropdown } from "shared/utilities/IEAUtility";
import { loadExportScopeValuesAsync } from "store/actions/DropdownValueActions";
import { loadUsersByTenantAsync } from "store/actions/UserActions";
import LabelWithContent from "../../../components/common/LabelWithContent";
import ScheduleSelectionDropdownItemRenderComponent from "../ScheduleSelectionDropdownItemRenderComponent";
import TenantUsersDropdown from "../TenantUsersDropdown";
import { useAppDispatch } from "store/hooks";

export interface OwnerConfigurationProps {
  ownerConfigList: ConfigForExchangeAgreementResponse[];
  direction: ConfigDirection;
  templateId: number;
  connectorId: number;
  scheduleId: number;
  loggedInUser: MySelfResponse;
  ownerRepresentatives: number[];
  onValueChange: (fieldName: string, value: any) => void;
  isDuplicatingIEA: boolean;
  onConnectorSelectionChange: (connector: ConnectorBaseResponse) => void;
  selectedConnector?: ConnectorBaseResponse | undefined;
  ownerRole: ExchangeRoleEnum;
  ownerConfigHostParameters?: string | undefined;
  ownerConfigSetting?: string;
  ownerConfig: ConfigResponse | null;
  onOwnerScheduleSelectionChange: (
    selection: ConnectorScheduleSimplifiedResponse
  ) => void;
  onOwnerRepresentativeChange: (users: number[]) => void;
}

export default function OwnerConfigurationForNewIEA(
  props: OwnerConfigurationProps
) {
  const {
    ownerConfigList,
    direction,
    templateId,
    connectorId,
    loggedInUser,
    ownerRepresentatives,
    onValueChange,
    onConnectorSelectionChange,
    onOwnerScheduleSelectionChange,
    scheduleId,
    isDuplicatingIEA,
    selectedConnector,
  } = props;

  const dispatch = useAppDispatch();
  const labelClassName =
    "ml-2 w-44 font-inter font-medium text-10px leading-15px";

  const [hostSystemName, setHostSystemName] = useState<string>("-");
  const [executeComponentName, setExecuteComponentName] = useState<string>("-");
  const [connectorDataSource, setConnectorDataSource] = useState<
    ConnectorBaseResponse[]
  >([]);
  const [scheduleDataSource, setScheduleDataSource] = useState<
    ConnectorScheduleSimplifiedResponse[]
  >([]);
  const [usersByTenant, setUsersByTenant] = useState<UserSimplifiedResponse[]>(
    []
  );

  const handleConnectorSelectionChange = ({
    selectedItem,
  }: {
    selectedItem: ConnectorBaseResponse;
  }) => {
    setHostSystemName(selectedItem.hostSystemName);
    setExecuteComponentName(selectedItem.executionComponentText);
    onValueChange("connectorId", selectedItem.id);
    onConnectorSelectionChange(selectedItem);
  };

  const resetTemplateDependantFields = () => {
    setHostSystemName("-");
    setExecuteComponentName("-");
    onValueChange("connectorId", 0);
    onValueChange("scheduleId", 0);
  };

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadExportScopeValuesAsync()).finally(hideLoadingPanel);
  }, []);

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadUsersByTenantAsync({ tenantId: loggedInUser.tenant.id }))
      .unwrap()
      .then((userList: UserSimplifiedResponse[]) => {
        // Removing myself from the user list because
        // I am already added a an owner representative.
        let filteredUserList = userList.filter(
          (user) => user.id !== loggedInUser.id
        );
        setUsersByTenant(filteredUserList);
      })
      .finally(hideLoadingPanel);
  }, []);

  useEffect(() => {
    resetTemplateDependantFields();
    const temporaryDataSource: ConnectorBaseResponse[] = [];

    for (let index = 0; index < ownerConfigList.length; index++) {
      if (
        ownerConfigList[index].direction === direction &&
        ownerConfigList[index].template?.id === templateId
      ) {
        temporaryDataSource.push(ownerConfigList[index].connector);
      }
    }

    // Filtering for Unique values.
    let filteredDataSource = temporaryDataSource.filter(
      (data, ind) =>
        ind === temporaryDataSource.findIndex((val) => val.id === data.id)
    );

    // we only allow to change connector under the same host system when duplicating IEA
    if (isDuplicatingIEA && selectedConnector) {
      filteredDataSource = filteredDataSource.filter(
        (ds) => ds.hostSystem === selectedConnector.hostSystem
      );
      setHostSystemName(selectedConnector.hostSystemName);
      setExecuteComponentName(selectedConnector.executionComponentText);
    }

    setConnectorDataSource(filteredDataSource);
  }, [direction, templateId, isDuplicatingIEA]);

  useEffect(() => {
    const temporaryDataSource: ConnectorScheduleSimplifiedResponse[] = [];

    for (let index = 0; index < ownerConfigList.length; index++) {
      if (
        ownerConfigList[index].direction === direction &&
        ownerConfigList[index].template?.id === templateId &&
        ownerConfigList[index].connector?.id === connectorId
      ) {
        temporaryDataSource.push(...ownerConfigList[index].connectorSchedules);
      }
    }

    // Filtering for Unique values.
    let filteredDataSource = temporaryDataSource.filter(
      (data, ind) =>
        ind === temporaryDataSource.findIndex((val) => val.id === data.id)
    );

    setScheduleDataSource(filteredDataSource);
  }, [connectorId]);

  return (
    <div className="space-y-4">
      <div className="flex gap-x-8">
        <LabelWithContent
          label="Connector name"
          addAsterisksymbol={true}
          content={
            <PromineoSelectBox
              width={328}
              disabled={templateId === 0}
              value={connectorId}
              placeholder="Select"
              items={connectorDataSource}
              displayExpr={"name"}
              valueExpr={"id"}
              onSelectionChanged={handleConnectorSelectionChange}
            />
          }
        />
        <LabelWithContent
          label="Host System"
          content={<div className={labelClassName}>{hostSystemName}</div>}
        />
      </div>

      <div className="flex gap-8">
        <LabelWithContent
          label="Schedule identifier"
          addAsterisksymbol={true}
          content={
            <PromineoSelectBox
              placeholder="Select"
              width={328}
              disabled={connectorId === 0}
              value={scheduleId}
              items={scheduleDataSource}
              itemRender={ScheduleSelectionDropdownItemRenderComponent}
              valueExpr={"id"}
              displayExpr={getDisplayExpressionForScheduleSelectorDropdown}
              onSelectionChanged={(selection: any) => {
                onOwnerScheduleSelectionChange(selection.selectedItem);
              }}
            />
          }
        />
        <LabelWithContent
          label="Execute component"
          content={<div className={labelClassName}>{executeComponentName}</div>}
        />{" "}
      </div>

      <div className="flex gap-x-8">
        <LabelWithContent
          label="Owner representatives"
          content={
            <div className="space-y-2 py-2 w-[328px]">
              <div className={labelClassName}>{loggedInUser.fullName}</div>

              <TenantUsersDropdown
                users={ownerRepresentatives.filter(
                  (ownerId) => ownerId !== loggedInUser.id
                )}
                width={328}
                maximumNumberOfUsers={3}
                minimumNumberOfUsers={0}
                userList={usersByTenant}
                onValueChange={(users: number[]) => {
                  props.onOwnerRepresentativeChange(users);
                }}
              />
            </div>
          }
        />
        <LabelWithContent
          label="Config"
          content={
            <div className={labelClassName}>
              {props.ownerConfig?.title ?? "-"}
            </div>
          }
        />
      </div>
    </div>
  );
}
