import { displayLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import PromineoViewGrid, {
  RowOperationConfig,
} from "components/common/grid/PromineoViewGrid";
import "components/common/grid/styles/PromineoUIGrid.css";
import { Column, Scrolling } from "devextreme-react/data-grid";
import ArchiveExchangeAgreementListingResponse from "interfaces/response/ArchiveExchangeAgreementListingResponse";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ARCHIVE_IEAS } from "shared/constants/RoutePathConstants";
import { loadExchangeAgreementDetailsAsync } from "store/actions/ExchangeAgreementActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import DuplicateIEAWizard from "../active-ieas/DuplicateIEAWizard";

interface Props {
  ieas: ArchiveExchangeAgreementListingResponse[];
  height: number;
}

export default function ArchiveIEAGrid(props: Props) {
  const { ieas, height } = props;
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  const toolbarConfig = useRef({dislplaySearchPanel: true});

  const [isIEADuplicateWizardVisible, setIsIEADuplicateWizardVisible] = useState<boolean>(false);

  const exchangeAgreement = useAppSelector((store) => store.exchangeAgreementData.selectedExchangeAgreement);

  const loadExchangeAgreementToDuplicate = (agreementId: number,)=>{
    if(agreementId){
      displayLoadingPanel();
      dispatch(loadExchangeAgreementDetailsAsync(agreementId))
      .unwrap()
      .then((response: ExchangeAgreementDetailedResponse)=>{
        setIsIEADuplicateWizardVisible(true);
      })
      .finally(hideLoadingPanel);
    }
  }
  const handleInactiveIEAClick = (iea: ArchiveExchangeAgreementListingResponse) => {
    navigate(`${ARCHIVE_IEAS}/${iea.id}`);
  };

  const handleRowClick = useCallback((data: any) => {
    handleInactiveIEAClick(data.data);
  }, []);

  const rowOperationConfig = useRef<RowOperationConfig>({
    visible: true,
    items: [
      {
        onClick: (data: any) => {
          const iea: ArchiveExchangeAgreementListingResponse = data.data;
          handleInactiveIEAClick(iea);
        },
        text: "View",
        visible: true,
      },
      {
        onClick: (data: {data: ArchiveExchangeAgreementListingResponse}) => handleDuplicateIEAClick(data.data),
        text: "Duplicate IEA",
        visibleFn: (data: {data: ArchiveExchangeAgreementListingResponse}) => {
          return data.data.isCurrentUserOwner;
        },
      },
    ],
  });


  const hideDuplicateIEAWizard = () => {
    setIsIEADuplicateWizardVisible(false);
  }

  const handleDuplicateIEAClick = (agreementData: ArchiveExchangeAgreementListingResponse)=>{
    if(agreementData.id){
      loadExchangeAgreementToDuplicate(agreementData.id);
    }
  }

  return (
    <>
      <PromineoViewGrid
        dataSource={ieas}
        height={height}
        className={`archive-ieas-grid promineo-ui-grid`}
        onRowClick={handleRowClick}
        toolbarConfig={toolbarConfig.current}
        rowOperationConfig={rowOperationConfig.current}
        noDataText="Your ILAP exchange agreements (IEAs) will appear here once they expire!"
      >
        <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
        <Column
          caption={"IEA Id"}
          dataField="id"
          alignment="left"
          width={100}
        />
        <Column caption={"IEA Title"} dataField="title" alignment="left" />
        <Column
          caption={"Template"}
          dataField="templateName"
          alignment="left"
        />
        <Column
          caption={"Sending connector (host system)"}
          dataField="sendingConnectorText"
          alignment="left"
        />
        <Column
          caption={"Receiving connector (host system)"}
          dataField="receivingConnectorText"
          alignment="left"
        />
        <Column caption={"Sender"} dataField="sender" alignment="left" />
        <Column caption={"Receiver"} dataField="receiver" alignment="left" />
        <Column caption={"Role"} dataField="roleText" alignment="left" />
        <Column
          caption={"Expiration date"}
          dataField="expirationDate"
          alignment="left"
          sortIndex={0}
          sortOrder={"asc"}
        />
      </PromineoViewGrid>
      {isIEADuplicateWizardVisible && <DuplicateIEAWizard onClose={hideDuplicateIEAWizard} exchangeAgreementToDuplicate={exchangeAgreement}/>}
    </>
  );
}
