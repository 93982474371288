import ErrorResponse from "interfaces/response/ErrorResponse";
import { ProtectedApiConnector } from "shared/connector/ApiConnector";
import { toastError } from "shared/utilities/ToastUtility";
import { globalExceptions } from "./ApiConstants";

const exceptionsToIgnoreForToastError = new Set<string>([
  globalExceptions.TenantNotFoundException,
  globalExceptions.UserIsDisabledException,
]);

const checkIfShouldIgnoreToastMessage = (error: ErrorResponse) => {
  return exceptionsToIgnoreForToastError.has(error.exceptionType);
};

const tryMakingRequestAsync = async <X>(
  fn: () => Promise<X>,
  displayError: boolean = true
) => {
  try {
    return await fn?.();
  } catch (error: any) {
    const shouldIgnoreToastMessage = checkIfShouldIgnoreToastMessage(error);
    if (displayError && !shouldIgnoreToastMessage) {
      toastError(
        error.validationErrorMessage
          ? error.validationErrorMessage
          : error.message
      );
    }
    throw error;
  }
};

export const getDataAsync = async <T = any>(
  routeName: string,
  displayError: boolean = true
) => {
  return await tryMakingRequestAsync(async () => {
    const response = await ProtectedApiConnector.get(routeName);
    return response.data as T;
  }, displayError);
};

export const postDataAsync = async <P, T>(
  routeName: string,
  data: P,
  displayError: boolean = true
) => {
  return await tryMakingRequestAsync(async () => {
    const response = await ProtectedApiConnector.post(routeName, data);
    return response.data as T;
  }, displayError);
};

export const deleteDataAsync = async <T>(
  routeName: string,
  displayError: boolean = true
) => {
  return await tryMakingRequestAsync(async () => {
    const response = await ProtectedApiConnector.delete(routeName);
    return response.data as T;
  }, displayError);
};

export const putDataAsync = async <P, T>(
  routeName: string,
  data: P,
  displayError: boolean = true
) => {
  return await tryMakingRequestAsync(async () => {
    const response = await ProtectedApiConnector.put(routeName, data);
    return response.data as T;
  }, displayError);
};

export const patchDataAsync = async <P, T>(
  routeName: string,
  data: P,
  displayError: boolean = true
) => {
  return await tryMakingRequestAsync(async () => {
    const response = await ProtectedApiConnector.patch(routeName, data);
    return response.data as T;
  }, displayError);
};
