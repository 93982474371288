import FieldValue from "interfaces/common/FieldValue";
import ValueMap from "interfaces/common/ValueMap";
import { GetNewId } from "shared/utilities/CommonUtility";
import { RootState } from "store/store";
import { createAppAsyncThunk } from "store/ThunkUtility";

export const validateAndPrepareBulkValueMaps = createAppAsyncThunk(
  "valueMapBulkInsertUI/validateAndPrepareBulkValueMaps",
  (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    let errors: string[] = [];
    const valueMaps: ValueMap[] = [];
    let inputWithLines = state.bulkInsertUIData.userInputText
      .trim()
      .split("\n");
    if (inputWithLines.length === 1 && inputWithLines[0].length === 0)
      inputWithLines = [];

    const addError = (error: string) => errors.push(error);

    inputWithLines.forEach((input: string, ind: number) => {
      let splitedInput = input.trim().split(";");
      splitedInput = splitedInput.map((i) => i.trim());
      let index = ind + 1;

      if (splitedInput.length > 0) {
        if (splitedInput.length === 3) {
          if (!splitedInput[0] || !splitedInput[1]) {
            addError(`Invalid input at line ${index}: ${input} 
                Value must be provided.`);
          } else {
            valueMaps.push({
              __KEY__: GetNewId(),
              templateValue: splitedInput[0],
              hostValue: splitedInput[1],
              hostValueDescription: splitedInput[2],
            });
          }
        } else {
          addError(
            `Invalid input at line ${index}: ${input} 
                      Each line should contains a template value, a host value and optionally a comment separated by ;(semicolone)`
          );
        }
      }
    });
    return { errors, valueMaps };
  }
);

export const validateAndPrepareBulkFieldValues = createAppAsyncThunk(
  "valueMapBulkInsertUI/validateAndPrepareBulkFieldValues",
  (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    let errors: string[] = [];
    const fieldValues: FieldValue[] = [];
    let inputWithLines = state.bulkInsertUIData.userInputText
      .trim()
      .split("\n");
    if (inputWithLines.length === 1 && inputWithLines[0].length === 0)
      inputWithLines = [];

    const addError = (error: string) => errors.push(error);

    inputWithLines.forEach((input: string, ind: number) => {
      let splitedInput = input.trim().split(";");
      splitedInput = splitedInput.map((i) => i.trim());

      let index = ind + 1;
      if (splitedInput.length > 0) {
        if (splitedInput.length === 1) {
          if (!splitedInput[0]) {
            addError(`Invalid input at line ${index}: ${input} 
            Value must be provided.`);
          } else {
            addError(`Invalid input at line ${index}: ${input} 
            Each line should contains a value and optionally a description separated by ;(semicolon)`);
          }
        } else if (splitedInput.length === 2) {
          if (!splitedInput[0]) {
            addError(
              `Invalid input at line ${index}: ${input} 
              Value must be provided.`
            );
          } else {
            fieldValues.push({
              __KEY__: GetNewId(),
              valueCode: splitedInput[0],
              description: splitedInput[1],
            });
          }
        } else {
          addError(
            `Invalid input at line ${index}: ${input} 
            Each line should contains a value and optionally a description separated by ;(semicolon)`
          );
        }
      }
    });

    return { errors, fieldValues };
  }
);
