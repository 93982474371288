import {
  getDownloadConflictsAsync,
  setConflictResolveApproach,
} from "apis/ConflictApi";
import DataTransferConflictResolveRequest from "interfaces/request/DataTransferConflictResolveRequest";
import { createAppAsyncThunk } from "store/ThunkUtility";

export const loadDownloadConflictsAsync = createAppAsyncThunk(
  "conflicts/loadDownloadConflicts",
  async (dataTransferId: number) => {
    return await getDownloadConflictsAsync(dataTransferId);
  }
);

export const saveConflictResolveApproachAsync = createAppAsyncThunk(
  "conflicts/saveConflictResolveApproach",
  async (args: {
    dataTransferId: number;
    conflictResolveRequest: DataTransferConflictResolveRequest;
  }) => {
    return await setConflictResolveApproach(
      args.dataTransferId,
      args.conflictResolveRequest
    );
  }
);
