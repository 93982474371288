import { StepThreeNewIeaProps } from "features/ieas/active-ieas/new-iea/StepThreeNewIEA";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { ExchangeRoleEnum } from "shared/enums/feature/ExchangeRoleEnum";
import { FrequencyType } from "shared/enums/feature/FrequencyType";

interface Props {
  ownerExchangeRole: ExchangeRoleEnum;
  exchangeAgreementToDuplicate?: ExchangeAgreementDetailedResponse | null;
}

interface HookResponseType {
  stepThreeProps: StepThreeNewIeaProps;
  scheduleRequest: ScheduleRequest;
  isScheduleRequestValid: boolean;
}

interface ScheduleRequest {
  startDate: string;
  expirationDate: string;
  labelIdentifiers: number[];
  comments: string;
  frequencyType: FrequencyType;
  lagInMinutes: number;
  predecessorAgreementId?: number;
  executionCronExpression: string;
}

const defaultScheduleRequest: ScheduleRequest = {
  startDate: "",
  expirationDate: "",
  labelIdentifiers: [],
  comments: "",
  frequencyType: -1 as FrequencyType.CronExpression,
  lagInMinutes: 0,
  executionCronExpression: "",
};

export default function useStepThreeNewIeaDataHook(
  props: Props
): HookResponseType {
  const { ownerExchangeRole, exchangeAgreementToDuplicate } = props;

  const [isValidRequest, setIsValidRequest] = useState(false);
  const [scheduleRequest, setScheduleRequest] = useState<ScheduleRequest>(
    defaultScheduleRequest
  );

  useEffect(() => {
    // Frequency type 'Predecessor' can be selected only for sender.
    // Thats why, we need to reset frequency dropdown datasource(to remove/add predecessor option) upon changing the direction.
    // Also we need to reset the frequncyType selection for the same reason.
    // We do not allow exchange role change when duplicating an IEA
    if(!exchangeAgreementToDuplicate)
    {
      setScheduleRequest((prev) => {
        return { ...prev, frequencyType: -1 as FrequencyType.CronExpression };
      });
    }
  }, [ownerExchangeRole]);

  useEffect(() => {
    let isValid = true;
    if (
      !scheduleRequest.startDate ||
      !scheduleRequest.expirationDate ||
      moment(scheduleRequest.startDate).isSameOrAfter(
        moment(scheduleRequest.expirationDate)
      ) ||
      scheduleRequest.frequencyType < 0 ||
      (scheduleRequest.frequencyType === FrequencyType.Predecessor &&
        !scheduleRequest.predecessorAgreementId) ||
      (scheduleRequest.frequencyType === FrequencyType.CronExpression &&
        !!!scheduleRequest.executionCronExpression) ||
      (scheduleRequest.frequencyType === FrequencyType.Manual &&
        scheduleRequest.executionCronExpression) // 'Manual' frequency must not have a CRON
    ) {
      isValid = false;
    }
    setIsValidRequest(isValid);
  }, [scheduleRequest]);

  useEffect(() => {
    if (exchangeAgreementToDuplicate) {
      let newScheduleRequest: ScheduleRequest = {
        startDate: exchangeAgreementToDuplicate.startDate,
        expirationDate: exchangeAgreementToDuplicate.isExpired
          ? ""
          : exchangeAgreementToDuplicate.expirationDate,
        labelIdentifiers: exchangeAgreementToDuplicate.labels.map((l) => l.id),
        comments: exchangeAgreementToDuplicate.comments,
        frequencyType: exchangeAgreementToDuplicate.frequencyType,
        lagInMinutes: exchangeAgreementToDuplicate.lagInMinutes ?? 0,
        predecessorAgreementId:
          exchangeAgreementToDuplicate.predecessorAgreement?.id,
        executionCronExpression:
          exchangeAgreementToDuplicate.executionCronExpression,
      };

      setScheduleRequest(newScheduleRequest);
    }
  }, [exchangeAgreementToDuplicate]);

  const handleFrequencyTypeChange = (frequencyType: FrequencyType) => {
    setScheduleRequest((prev) => {
      return {
        ...prev,
        frequencyType,
        predecessorAgreementId: undefined,
        lagInMinutes: 0,
      };
    });
  };

  const handleLagMinutesChange = (lagInMinutes: number) => {
    setScheduleRequest((prev) => {
      return { ...prev, lagInMinutes: lagInMinutes };
    });
  };

  const handlePredecessorAgreementIdChange = (value: number) => {
    setScheduleRequest((prev) => {
      return { ...prev, predecessorAgreementId: value };
    });
  };

  const handleStartDateChange = (value: string) => {
    setScheduleRequest((prev) => {
      return { ...prev, startDate: value, expirationDate: "" };
    });
  };

  const handleExpirationDateChange = (value: string) => {
    setScheduleRequest((prev) => {
      return { ...prev, expirationDate: value };
    });
  };

  const handleLabelIdentifierChange = (values: number[]) => {
    setScheduleRequest((prev) => {
      return { ...prev, labelIdentifiers: values };
    });
  };

  const handleCommentChange = (value: string) => {
    setScheduleRequest((prev) => {
      return { ...prev, comments: value };
    });
  };

  const handleCronExpressionChange = (value: string) => {
    setScheduleRequest((prev) => {
      return { ...prev, executionCronExpression: value };
    });
  };

  const response = useMemo<HookResponseType>(() => {
    return {
      stepThreeProps: {
        scheduleProps: {
          ownerExchangeRole: ownerExchangeRole,
          frequencyType: scheduleRequest.frequencyType,
          executionCronExpression: scheduleRequest.executionCronExpression,
          lagInMinutes: scheduleRequest.lagInMinutes,
          labelIdentifiers: scheduleRequest.labelIdentifiers,
          comments: scheduleRequest.comments,
          onValueChange: () => {},
          onFrequencyTypeChange: handleFrequencyTypeChange,
          onLagMinutesChange: handleLagMinutesChange,
          onPredecessorAgreementIdChange: handlePredecessorAgreementIdChange,
          onCommentChange: handleCommentChange,
          onExpirationDateChange: handleExpirationDateChange,
          onStartDateChange: handleStartDateChange,
          onLabelIndentifiersChange: handleLabelIdentifierChange,
          onCronExpressionChange: handleCronExpressionChange,
          startDate: scheduleRequest.startDate,
          expirationDate: scheduleRequest.expirationDate,
        },
      },
      isScheduleRequestValid: isValidRequest,
      scheduleRequest: scheduleRequest,
    };
  }, [scheduleRequest, props, isValidRequest]);

  return response;
}
