import {
  AsyncThunkOptions,
  AsyncThunkPayloadCreator,
  createAsyncThunk,
  Dispatch,
} from "@reduxjs/toolkit";
import { RootState } from "./store";

declare type AsyncThunkConfig = {
  state?: RootState;
  dispatch?: Dispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};

export function createAppAsyncThunk<
  Returned,
  ThunkArg = void,
  ThunkApiConfig extends AsyncThunkConfig = {}
>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>,
  options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>
) {
  const thunkOptions = {
    ...options,
    serializeError: (error: any) => {
      // Here the type of error is ErrorResponse (that we are returning from ErrorParser).
      // The default error serializer of thunk ommits other properties except the 'message' property from this error response.
      // As a result, we are unable to read the error status code and type without using rejectWithValue utility function from thunk.
      // To overcome the issue, here we are adding our own serializeError function to override the default serialization and returning
      // the error response as it is without any modification so that we can read error status code and exception type from anywhere.
      return error;
    },
  };

  return createAsyncThunk(typePrefix, payloadCreator, thunkOptions);
}
