import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import NewIEAWizard from "./new-iea/NewIEAWizard";
import { useEffect, useState } from "react";
import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";

interface Props {
  onClose: () => void;
  exchangeAgreementToDuplicate: ExchangeAgreementDetailedResponse | null;
}

export default function DuplicateIEAWizard(props: Props) {
  const [exchangeAgreement, setExchangeAgreement] = useState<ExchangeAgreementDetailedResponse | null>(null);
  const [showDuplicateIeaUpdatePendingWarning, setShowDuplicateIeaUpdatePendingWarning] = useState<boolean>(false);

  const setExchangeAgreementForDuplication = ()=>{
    if(props.exchangeAgreementToDuplicate){
      var updatedExchangeAgreement: ExchangeAgreementDetailedResponse = {
        ...props.exchangeAgreementToDuplicate,
        expirationDate: "",
      };
      setExchangeAgreement(updatedExchangeAgreement);
    }
  }

  useEffect(() => {
    if (props.exchangeAgreementToDuplicate) {
      if (props.exchangeAgreementToDuplicate.updateRequired) {
        setShowDuplicateIeaUpdatePendingWarning(true);
      } else {
        setExchangeAgreementForDuplication();
      }
    }
  }, [props.exchangeAgreementToDuplicate]);

  const handleDuplicateIEAWarningDialogProceedClick = ()=>{
    setShowDuplicateIeaUpdatePendingWarning(false);
    setExchangeAgreementForDuplication();
  }

  const handleDuplicateIEAWarningDialogCancelClick = ()=>{
    props.onClose();
  }

  return (
    <>
      {exchangeAgreement && (
        <NewIEAWizard
          onClose={props.onClose}
          exchangeAgreementToDuplicate={exchangeAgreement}
        />
      )}

      {showDuplicateIeaUpdatePendingWarning && (
        <PromineoConfirmationDialog
          onConfirm={handleDuplicateIEAWarningDialogProceedClick}
          onCancel={handleDuplicateIEAWarningDialogCancelClick}
          content="Warning"
          subContent={`The IEA is not up to date. Proceeding will copy the latest configurations.`}
          cancelButtonText="Cancel"
          confirmButtonText="Proceed"
        />
      )}
    </>
  );
}


