import ActiveExchangeAgreementListingResponse from "interfaces/response/ActiveExchangeAgreementListingResponse";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import moment from "moment";
import { NewExchangeAgreementActionRequired } from "shared/enums/feature/NewExchangeAgreementActionRequired";

export const CheckIfPartnerResponsibleFieldsAreFilled = (selectedExchangeAgreement: ExchangeAgreementDetailedResponse) => {
  return (
    selectedExchangeAgreement.partnerRepresentatives?.length > 0 &&
    selectedExchangeAgreement.partnerConnector &&
    selectedExchangeAgreement.partnerSchedule && 
    selectedExchangeAgreement.partnerConfigSettings
  );
}

export const IsSignedByPartner = (selectedExchangeAgreement: ExchangeAgreementDetailedResponse) => {
  return (selectedExchangeAgreement.partnerSigner && selectedExchangeAgreement.partnerSignatureTime);
}

export const IsSignedByOwner = (selectedExchangeAgreement: ExchangeAgreementDetailedResponse) => {
  return (selectedExchangeAgreement.ownerSigner && selectedExchangeAgreement.ownerSignatureTime);
}

export const getHeaderInformationText = (selectedExchangeAgreement: ExchangeAgreementDetailedResponse, isCurrentUserOwner: boolean, isCurrentUserPartner: boolean) => {
  let headerText = "";

  if (selectedExchangeAgreement?.actionRequiredIfInactive) {
    if (isCurrentUserOwner && !isCurrentUserPartner) {
      if (selectedExchangeAgreement.actionRequiredIfInactive === NewExchangeAgreementActionRequired.Complete) {
        headerText = "Fill out owner configuration";
      }
      else if (selectedExchangeAgreement.actionRequiredIfInactive === NewExchangeAgreementActionRequired.WaitingForPartner) {
        headerText = "Wait for partner to fill out partner configuration";
      }
      else if (selectedExchangeAgreement.actionRequiredIfInactive === NewExchangeAgreementActionRequired.WaitingForPartnerSign) {
        headerText = "Wait for partner to sign IEA";
      }
      else if (selectedExchangeAgreement.actionRequiredIfInactive === NewExchangeAgreementActionRequired.SignOff) {
        headerText = "Sign IEA to complete IEA";
      }
      else {
        headerText = `IEA completed and will start on ${selectedExchangeAgreement.startDate}.`
      }
    }
    else if (isCurrentUserPartner && !isCurrentUserOwner) {
      if (selectedExchangeAgreement.actionRequiredIfInactive === NewExchangeAgreementActionRequired.Complete) {
        headerText = "Fill out partner configuration";
      }
      else if (selectedExchangeAgreement.actionRequiredIfInactive === NewExchangeAgreementActionRequired.WaitingForOwner) {
        headerText = "Wait for owner to fill out owner configuration";
      }
      else if (selectedExchangeAgreement.actionRequiredIfInactive === NewExchangeAgreementActionRequired.WaitingForOwnerSign) {
        headerText = "Wait for owner to sign IEA";
      }
      else if (selectedExchangeAgreement.actionRequiredIfInactive === NewExchangeAgreementActionRequired.SignOff) {
        headerText = "Sign IEA to continue";
      }
    }
    else if (isCurrentUserOwner && isCurrentUserPartner) {
      if (selectedExchangeAgreement.actionRequiredIfInactive !== NewExchangeAgreementActionRequired.Complete) {
        if (IsSignedByPartner(selectedExchangeAgreement)) {
          if (IsSignedByOwner(selectedExchangeAgreement)) {
            headerText = `IEA completed and will start on ${selectedExchangeAgreement.startDate}.`
          }
          else {
            headerText = "Wait for owner to sign IEA";
          }
        }
        else {
          headerText = "Wait for partner to sign IEA";
        }
      }
      else {
        headerText = "Fill out partner configuration";
      }
    }
  }

  return headerText;
}

export const exchangeAgreementUpdateColumnSortComparator = (
  exchangeAgreement1Data: any,
  exchangeAgreement2Data: any
) => {
  var exchangeAgreement1: ActiveExchangeAgreementListingResponse = exchangeAgreement1Data.exchangeAgreement;
  var userHasPartnerRoleForExchangeAgreement1: boolean = exchangeAgreement1Data.isPartner;

  var exchangeAgreement2: ActiveExchangeAgreementListingResponse = exchangeAgreement2Data.exchangeAgreement;
  var userHasPartnerRoleForExchangeAgreement2: boolean = exchangeAgreement2Data.isPartner;

  // Here is the priority list:
  // If Both ExchangeAgreements has updateRequired value = "True" then we will follow the default order.
  // If ExchangeAgreement1 has updateRequired value = "True" and ExchangeAgreement2 has updateRequired value = "False" then
    // ExchangeAgreement1 will come before ExchangeAgreement2.
  // If ExchangeAgreement1 has updateRequired value = "False" and ExchangeAgreement2 has updateRequired value = "True" then
    // ExchangeAgreement2 will come before ExchangeAgreement1.
  // If Both ExchangeAgreements has updateRequired value = "False" then we will check the values of "isUpdatePerformedByOwner".

  if (exchangeAgreement1.updateRequired && exchangeAgreement2.updateRequired) {
    // Follow the default order.
    return 0;
  }
  else if (!exchangeAgreement1.updateRequired && exchangeAgreement2.updateRequired) {
    // ExchangeAgreement2 will come before ExchangeAgreement1.
    return 1;
  }
  else if (exchangeAgreement1.updateRequired && !exchangeAgreement2.updateRequired) {
    // ExchangeAgreement1 will come before ExchangeAgreement2.
    return -1;
  }
  else {
    if (userHasPartnerRoleForExchangeAgreement1 && userHasPartnerRoleForExchangeAgreement2) {
      if (exchangeAgreement1.isUpdatePerformedByOwner && exchangeAgreement2.isUpdatePerformedByOwner) {
        // Follow default order.
        return 0;
      }
      else if (!exchangeAgreement1.isUpdatePerformedByOwner && exchangeAgreement2.isUpdatePerformedByOwner) {
        // ExchangeAgreement2 will come before ExchangeAgreement1.
        return 1;
      }
      else if (exchangeAgreement1.isUpdatePerformedByOwner && !exchangeAgreement2.isUpdatePerformedByOwner) {
        // ExchangeAgreement1 will come before ExchangeAgreement2.
        return -1;
      }
      else {
        // Follow default order.
        return 0;
      }
    }
    else if (userHasPartnerRoleForExchangeAgreement1 && !userHasPartnerRoleForExchangeAgreement2) {
      if (exchangeAgreement1.isUpdatePerformedByOwner) {
        return -1;
      }
      else {
        return 0;
      }
    }
    else if (!userHasPartnerRoleForExchangeAgreement1 && userHasPartnerRoleForExchangeAgreement2) {
      if (exchangeAgreement2.isUpdatePerformedByOwner) {
        return 1;
      }
      else {
        return 0;
      }
    }
  }
};

export const getValidMinimumExpirationDate = (startDate: string) => {
  return startDate
    ? moment.max(moment(startDate), moment.utc()).add(1, "day").toString()
    : undefined;
}