import {
  getDownloadDataTransferLogAsync,
  getUploadDataTransferLogAsync,
} from "apis/DataTransferApi";
import { createAppAsyncThunk } from "store/ThunkUtility";

export const loadUploadDataTransferLogAsync = createAppAsyncThunk(
  "DataTransfer/loadUploadDataTransferLogAsync",
  async (dataTransferId: number) => {
    return await getUploadDataTransferLogAsync(dataTransferId);
  }
);

export const loadDownloadDataTransferLogAsync = createAppAsyncThunk(
  "DataTransfer/loadDownloadDataTransferLogAsync",
  async (dataTransferId: number) => {
    return await getDownloadDataTransferLogAsync(dataTransferId);
  }
);
