import {
  createAutonomousComponentAsync,
  createServiceTokenAsync,
  deleteAutonomousComponentAsync,
  deleteServiceTokenAsync,
  getAutonomousComponentsAsync,
  getServiceTokensAsync,
  updateAutonomousComponentAsync,
  updateServiceTokenAsync,
} from "apis/AutonomousComponentApi";
import ClientCreateRequest from "interfaces/request/autonomous-components/ClientCreateRequest";
import ServiceTokenCreateRequest from "interfaces/request/autonomous-components/ServiceTokenCreateRequest";
import ServiceTokenUpdateRequest from "interfaces/request/autonomous-components/ServiceTokenUpdateRequest";
import { createAppAsyncThunk } from "store/ThunkUtility";

export const loadAutonomousComponentsAsync = createAppAsyncThunk(
  "autonomousComponents/loadAutonomousComponents",
  async () => {
    return await getAutonomousComponentsAsync();
  }
);

export const addNewAutonomousComponentAsync = createAppAsyncThunk(
  "autonomousComponents/addNewAutonomousComponents",
  async (data: ClientCreateRequest) => {
    return await createAutonomousComponentAsync(data);
  }
);

export const removeAutonomousComponentAsync = createAppAsyncThunk(
  "autonomousComponents/removeAutonomousComponent",
  async (componentId: number) => {
    return await deleteAutonomousComponentAsync(componentId);
  }
);

export const modifyAutonomousComponentsAsync = createAppAsyncThunk(
  "autonomousComponents/modifyAutonomousComponents",
  async ({
    componentId,
    data,
  }: {
    componentId: number;
    data: ClientCreateRequest;
  }) => {
    return await updateAutonomousComponentAsync(componentId, data);
  }
);

export const loadAutonomousComponentServiceTokensAsync = createAppAsyncThunk(
  "autonomousComponents/loadServiceTokens",
  async (componentId: number) => {
    return await getServiceTokensAsync(componentId);
  }
);

export const addNewAutonomousComponentServiceTokenAsync = createAppAsyncThunk(
  "autonomousComponents/addNewServiceToken",
  async ({
    componentId,
    data,
  }: {
    componentId: number;
    data: ServiceTokenCreateRequest;
  }) => {
    return await createServiceTokenAsync(componentId, data);
  }
);

export const removeAutonomousComponentServiceTokenAsync = createAppAsyncThunk(
  "autonomousComponents/removeServiceToken",
  async (serviceTokenId: number) => {
    return await deleteServiceTokenAsync(serviceTokenId);
  }
);

export const modifyAutonomousComponentServiceTokenAsync = createAppAsyncThunk(
  "autonomousComponents/modifyServiceToken",
  async ({
    serviceTokenId,
    data,
  }: {
    serviceTokenId: number;
    data: ServiceTokenUpdateRequest;
  }) => {
    return await updateServiceTokenAsync(serviceTokenId, data);
  }
);
