import {
  createConnectorAsync,
  deleteConnectorAsync,
  generateConnectorEventAsync,
  getConnectorDetailsAsync,
  getConnectorEventStatusAsync,
  getConnectorHostFieldsAsync,
  getConnectorsForCurrentUserAsync,
  getSimplifiedConnectorsAsync,
  updateConnectorAsync,
} from "apis/ConnectorApi";
import ConnectorEventCreateRequest from "interfaces/request/ConnectorEventCreateRequest";
import ConnectorModifyRequest from "interfaces/request/ConnectorModifyRequest";
import ConnectorWriteBaseRequest from "interfaces/request/ConnectorWriteBaseRequest";
import HostFieldResponse from "interfaces/response/HostFieldResponse";
import ConnectorDetailsResponse from "interfaces/response/connector/ConnectorDetailsResponse";
import { createAppAsyncThunk } from "store/ThunkUtility";

export const loadConnectorsForCurrentUserAsync = createAppAsyncThunk(
  "Connecotrs/loadConnectorsForCurrentUser",
  async () => {
    return await getConnectorsForCurrentUserAsync();
  }
);

export const loadConnectorDetailsAsync = createAppAsyncThunk(
  "Connecotrs/loadConnectorDetails",
  async (connectorId: number) => {
    return await getConnectorDetailsAsync(connectorId);
  }
);

export const createConnector = createAppAsyncThunk(
  "Connecotrs/createConnector",
  async (connectorRequest: ConnectorWriteBaseRequest) => {
    return await createConnectorAsync(connectorRequest);
  }
);

export const updateConnector = createAppAsyncThunk<
  ConnectorDetailsResponse,
  { connectorId: number; connectorRequest: ConnectorModifyRequest }
>("Connecotrs/updateConnector", async (request) => {
  return await updateConnectorAsync(
    request.connectorId,
    request.connectorRequest
  );
});

export const loadSimplifiedConnectorsAsync = createAppAsyncThunk(
  "Connecotrs/loadSimplifiedConnectors",
  async () => {
    return await getSimplifiedConnectorsAsync();
  }
);

export const removeConnectorAsync = createAppAsyncThunk(
  "Connecotrs/removeConnector",
  async (connectorId: number, { rejectWithValue }) => {
    try {
      return await deleteConnectorAsync(connectorId);
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const generateConnectorPersistEventAsync = createAppAsyncThunk(
  "Connecotrs/generateConnectorEventAsync",
  async (connectorEventCreateRequest: ConnectorEventCreateRequest) => {
    return await generateConnectorEventAsync(connectorEventCreateRequest);
  }
);

export const loadConnectorEventStatusAsync = createAppAsyncThunk(
  "Connecotrs/getConnectorEventStatusAsync",
  async (connectorId: number) => {
    return await getConnectorEventStatusAsync(connectorId);
  }
);

export const loadConnectorHostFieldAsync = createAppAsyncThunk<
  HostFieldResponse[],
  { connectorId: number; includeAll: boolean; userFieldId: number }
>("Connecotrs/loadConnectorHostFieldAsync", async (request) => {
  return await getConnectorHostFieldsAsync(
    request.connectorId,
    request.includeAll,
    request.userFieldId
  );
});
