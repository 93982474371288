import { DropdownOptions } from "components/common/dropdown-box/PromineoDropdownBox";
import PromineoTreeViewDropdown from "components/common/tree-view-dropdown/PromineoTreeViewDropdown";
import { TreeViewOptions } from "components/common/tree-view/PromineoTreeView";
import { IDropDownBoxOptions } from "devextreme-react/drop-down-box";
import { ITreeViewOptions } from "devextreme-react/tree-view";
import TemplateSimplifiedResponse from "interfaces/response/TemplateSimplifiedResponse";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ConnectorAndTemplateDropdownWidth } from "shared/constants/ConfigUIConstants";

export interface TemplateDropdownSourceType {
  own: TemplateSimplifiedResponse[];
  received: TemplateSimplifiedResponse[];
}

interface Props {
  templateDataSource: TemplateDropdownSourceType;
  onSelectionChange: (templateId: number, title: string) => void;
  selectedTemplateId?: number;
  width?: number;
  disabled?: boolean;
}

export default function TemplateSelectorDropdown(props: Props) {
  const [templateSource, setTemplateSource] = useState<any>([]);
  const allTemplates = useRef<TemplateSimplifiedResponse[]>([]);

  useEffect(() => {
    if (props.templateDataSource) {
      const simplifiedTemplates = props.templateDataSource;
      const templates: any[] = [
        {
          id: "own",
          title: "Tenant templates",
        },
        {
          id: "received",
          title: "Received templates",
        },
      ];

      simplifiedTemplates.own.forEach((template) => {
        templates.push({ ...template, parent: "own" });
      });

      simplifiedTemplates.received.forEach((template) => {
        templates.push({ ...template, parent: "received" });
      });

      setTemplateSource(templates);
      allTemplates.current = [
        ...simplifiedTemplates.own,
        ...simplifiedTemplates.received,
      ];
    }
  }, [props.templateDataSource]);

  const handleSelectionChanged = useCallback((data: any) => {
    if (data && data.length) {
      const selectedId = data[0];
      const title =
        allTemplates.current.find((t) => t.id === selectedId)?.title ?? "";
      props.onSelectionChange(selectedId, title);
    }
  }, []);

  const treeViewOptions = useRef<TreeViewOptions & ITreeViewOptions>({
    showCheckBoxesMode: "none",
    selectionMode: "single",
    selectByClick: true,
    displayExpr: "title",
    dataStructure: "plain",
    parentIdExpr: "parent",
    keyExpr: "id",
  });

  const isSelectable = useCallback((data: any) => {
    return !!data.parent;
  }, []);

  const dropdownOption = useMemo<DropdownOptions & IDropDownBoxOptions>(() => {
    return {
      disabled: props.disabled,
      displayExpr: "title",
      valueExpr: "id",
      borderRound: "small",
      placeholder: "Select",
      width: props.width ?? ConnectorAndTemplateDropdownWidth,
      value: [props.selectedTemplateId],
    };
  }, [props.selectedTemplateId, props.width]);

  return (
    <PromineoTreeViewDropdown
      treeViewOptions={treeViewOptions.current}
      checkIfIsSelectable={isSelectable}
      dataSource={templateSource}
      shouldClosePopupOnSelect={true}
      onSelectionChanged={handleSelectionChanged}
      popupWidth={props.width ?? ConnectorAndTemplateDropdownWidth}
      dropdownOptions={dropdownOption}
      preventTogggleSelection={true}
    />
  );
}
