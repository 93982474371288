import { StepTwoNewIeaProps } from "features/ieas/active-ieas/new-iea/StepTwoNewIEA";
import ConfigForExchangeAgreementResponse from "interfaces/response/ConfigForExchangeAgreementResponse";
import ConfigResponse from "interfaces/response/ConfigResponse";
import ConnectorScheduleSimplifiedResponse from "interfaces/response/ConnectorScheduleSimplifiedResponse";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import MySelfResponse from "interfaces/response/MySelfResponse";
import ConnectorBaseResponse from "interfaces/response/connector/ConnectorBaseResponse";
import { useEffect, useMemo, useState } from "react";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { ExchangeRoleEnum } from "shared/enums/feature/ExchangeRoleEnum";

interface Props {
  ownerConfigList: ConfigForExchangeAgreementResponse[];
  direction: ConfigDirection;
  templateId: number;
  loggedInUser: MySelfResponse;
  ownerConfig: ConfigResponse | null;
  exchangeAgreementToDuplicate?: ExchangeAgreementDetailedResponse | null;
}

interface HookResponseType {
  stepTwoProps: StepTwoNewIeaProps;
  ownerConfigRequest: OwnerConfigRequest;
  isOwnerConfigRequestValid: boolean;
}

interface OwnerConfigRequest {
  selectedTemplateId: number;
  selectedDirection: ConfigDirection;
  connectorId: number;
  scheduleId: number;
  ownerRepresentatives: number[];
  ownerSchedule?: ConnectorScheduleSimplifiedResponse;
  selectedConnector?: ConnectorBaseResponse;
}

const defaultOwnerConfigRequest: OwnerConfigRequest = {
  selectedTemplateId: 0,
  connectorId: 0,
  scheduleId: 0,
  selectedDirection: ConfigDirection.Receiving,
  ownerRepresentatives: [],
};

export default function useStepTwoNewIeaDataHook(
  props: Props
): HookResponseType {
  const {
    ownerConfigList,
    direction,
    templateId,
    loggedInUser,
    ownerConfig,
    exchangeAgreementToDuplicate,
  } = props;

  const [isValidRequest, setIsValidRequest] = useState(false);
  const [ownerConfigRequest, setOwnerConfigRequest] =
    useState<OwnerConfigRequest>({
      ...defaultOwnerConfigRequest,
      selectedTemplateId: exchangeAgreementToDuplicate
        ? exchangeAgreementToDuplicate.template.id
        : 0,
      selectedDirection:
        exchangeAgreementToDuplicate &&
        exchangeAgreementToDuplicate.ownerRole === ExchangeRoleEnum.Sender
          ? ConfigDirection.Sending
          : ConfigDirection.Receiving,
      ownerRepresentatives: [loggedInUser.id],
    });

  useEffect(() => {
    if (exchangeAgreementToDuplicate) {
      let sourceIEAOwnerConfigDirection =
        exchangeAgreementToDuplicate.ownerRole === ExchangeRoleEnum.Sender
          ? ConfigDirection.Sending
          : ConfigDirection.Receiving;
      if (
        sourceIEAOwnerConfigDirection !== direction ||
        exchangeAgreementToDuplicate.template.id !== templateId
      ) {
        return;
      }
      let connector: ConnectorBaseResponse | undefined = undefined;
      let connectorId = 0;
      let schedule: ConnectorScheduleSimplifiedResponse | undefined = undefined;
      let scheduleId = 0;
      let ownerRepresentativeIdentifiers =
        exchangeAgreementToDuplicate.ownerRepresentatives
          .filter((or) => or.id !== loggedInUser.id)
          .map((or) => or.id);
      ownerRepresentativeIdentifiers = [
        loggedInUser.id,
        ...ownerRepresentativeIdentifiers,
      ];

      let configListFilterbyDirectionAndTemplate = ownerConfigList.filter(
        (c) => c.direction === direction && c.template.id === templateId
      );

      if (
        configListFilterbyDirectionAndTemplate &&
        configListFilterbyDirectionAndTemplate.find(
          (cn) =>
            cn.connector.id === exchangeAgreementToDuplicate.ownerConnector.id
        )
      ) {
        connector = exchangeAgreementToDuplicate.ownerConnector;
        connectorId = exchangeAgreementToDuplicate.ownerConnector.id;

        let schedulesListFromAllConnectors: ConnectorScheduleSimplifiedResponse[] =
          [];
        configListFilterbyDirectionAndTemplate.forEach((cn) => {
          schedulesListFromAllConnectors.push(...cn.connectorSchedules);
        });

        if (
          schedulesListFromAllConnectors.find(
            (s) => s.id === exchangeAgreementToDuplicate.ownerSchedule.id
          )
        ) {
          schedule = exchangeAgreementToDuplicate.ownerSchedule;
          scheduleId = exchangeAgreementToDuplicate.ownerSchedule.id;
        }
      }

      let newOwnerConfigrequest: OwnerConfigRequest = {
        selectedTemplateId: templateId,
        selectedDirection: direction,
        connectorId: connectorId,
        scheduleId: scheduleId,
        ownerRepresentatives: ownerRepresentativeIdentifiers,
        ownerSchedule: schedule,
        selectedConnector: connector,
      };
      setOwnerConfigRequest(newOwnerConfigrequest);
    }
  }, [exchangeAgreementToDuplicate, direction, templateId, ownerConfigList]);

  useEffect(() => {
    setOwnerConfigRequest((prev) => {
      return {
        ...prev,
        selectedTemplateId: templateId,
        scheduleId: 0,
        connectorId: 0,
      };
    });
  }, [templateId]);

  useEffect(() => {
    setOwnerConfigRequest((prev) => {
      return {
        ...prev,
        scheduleId: 0,
        connectorId: 0,
        selectedDirection: direction,
      };
    });
  }, [direction]);

  useEffect(() => {
    let isValid = true;
    if (
      !ownerConfigRequest.scheduleId ||
      !ownerConfigRequest.connectorId ||
      !ownerConfigRequest.ownerRepresentatives.length
    ) {
      isValid = false;
    }
    setIsValidRequest(isValid);
  }, [ownerConfigRequest]);

  const onConnectorChange = (connector: ConnectorBaseResponse) => {
    setOwnerConfigRequest((prev) => {
      return {
        ...prev,
        connectorId: connector.id,
        selectedConnector: connector,
        scheduleId: 0,
        ownerSchedule: undefined,
      };
    });
  };

  const onScheduleChange = (schedule: ConnectorScheduleSimplifiedResponse) => {
    setOwnerConfigRequest((prev) => {
      return { ...prev, scheduleId: schedule.id, ownerSchedule: schedule };
    });
  };

  const onOwnerRepresentativeChange = (values: number[]) => {
    setOwnerConfigRequest((prev) => {
      const validValues = values.filter((v) => v && v > 0);
      return {
        ...prev,
        ownerRepresentatives: [loggedInUser.id, ...validValues],
      };
    });
  };

  const response = useMemo<HookResponseType>(() => {
    return {
      stepTwoProps: {
        ownerConfigurationProps: {
          direction: direction,
          templateId: templateId,
          loggedInUser: loggedInUser,
          ownerRole:
            direction === ConfigDirection.Receiving
              ? ExchangeRoleEnum.Receiver
              : ExchangeRoleEnum.Sender,
          connectorId: ownerConfigRequest.connectorId,
          scheduleId: ownerConfigRequest.scheduleId,
          ownerConfig: ownerConfig,
          ownerConfigList: ownerConfigList,
          ownerRepresentatives: ownerConfigRequest.ownerRepresentatives,
          selectedConnector: ownerConfigRequest.selectedConnector,
          isDuplicatingIEA: exchangeAgreementToDuplicate ? true : false,
          // TODO: Remove
          onValueChange: () => {},
          onOwnerRepresentativeChange: onOwnerRepresentativeChange,
          onConnectorSelectionChange: onConnectorChange,
          onOwnerScheduleSelectionChange: onScheduleChange,
        },
      },
      isOwnerConfigRequestValid: isValidRequest,
      ownerConfigRequest,
    };
  }, [ownerConfigRequest, props, isValidRequest, exchangeAgreementToDuplicate]);

  return response;
}
