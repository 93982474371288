import {
  changeFieldsContentControlForSystemAdminAsync,
  createFieldsForSystemAdminAsync,
  getSystemUsersAsync,
  loadIlapTermFieldsForSystemAdminAsync,
  loadSystemLevelFieldDetailsAsync,
  modifyIlapTermBySystemAdminAsync,
  modifyIlapTermWithValuesAsync,
} from "apis/SystemAdminApi";
import SystemFieldCreateRequest from "interfaces/request/FieldCreateRequest";
import FieldManageRequest from "interfaces/request/FieldManageRequest";
import FieldUpdateRequest from "interfaces/request/FieldUpdateRequest";
import SystemLevelContentControlChangeRequest from "interfaces/request/SystemLevelContentControlChangeRequest";
import { createAppAsyncThunk } from "store/ThunkUtility";

export const getIlapTermFieldsForTenantAdminAsync = createAppAsyncThunk(
  "system-admin/getFieldsForSystemAdmin",
  async () => {
    return await loadIlapTermFieldsForSystemAdminAsync();
  }
);

export const createFieldsForSystemAdmin = createAppAsyncThunk(
  "system-admin/createFieldsForSystemAdmin",
  async (systemFieldCreateRequest: SystemFieldCreateRequest) => {
    return await createFieldsForSystemAdminAsync(systemFieldCreateRequest);
  }
);

export const updateFieldsContentControlForSystemAdminAsync =
  createAppAsyncThunk(
    "system-admin/updateFieldsContentControlForSystemAdmin",
    async (data: {
      fieldId: number;
      request: SystemLevelContentControlChangeRequest;
    }) => {
      return await changeFieldsContentControlForSystemAdminAsync(
        data.fieldId,
        data.request
      );
    }
  );

export const getFieldForSystemAdminAsync = createAppAsyncThunk(
  "system-admin/getFieldForSystemAdminAsync",
  async (fieldId: number) => {
    return await loadSystemLevelFieldDetailsAsync(fieldId);
  }
);

export const updateIlapTermWithValuesAsync = createAppAsyncThunk(
  "system-admin/updateIlapTermWithValues",
  async (data: { systemFieldId: number; request: FieldManageRequest }) => {
    return await modifyIlapTermWithValuesAsync(
      data.systemFieldId,
      data.request
    );
  }
);

export const updateIlapTermBySystemAdminAsync = createAppAsyncThunk(
  "system-admin/updateIlapTermBySystemAdmin",
  async (data: { fieldId: number; request: FieldUpdateRequest }) => {
    return await modifyIlapTermBySystemAdminAsync(data.fieldId, data.request);
  }
);

export const getSystemUsersForTenantAdminAsync = createAppAsyncThunk(
  "system-admin/getSystemUsersForSystemAdmin",
  async () => {
    return await getSystemUsersAsync();
  }
);
