import PromineoViewGrid, {
  PromineoGridToolbarConfig,
} from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import AddButtonCellTemplate from "../../config-common/AddButtonCellTemplate";
import ViewValueMapButtonCellTemplate from "../../config-common/ViewValueMapButtonCellTemplate";
import AddMappingFormulaDialog from "./add-mapping-formula-dialog/AddMappingFormulaDialog";
import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import AddIlapCoreFieldPopOverGrid from "./AddIlapCoreFieldPopOverGrid";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { loadCoreFieldsAsync } from "store/actions/CoreFieldActions";
import CoreFieldResponse from "interfaces/response/CoreFieldResponse";
import AddValueMapsWithContentControlDialog from "./AddValueMapsWithContentControlDialog";
import AddValueMapsWithNoContentControlDialog from "./AddValueMapsWithNoContentControlDialog";
import { GetNewId, deepCopyObject } from "shared/utilities/CommonUtility";
import MappingFormulaCellTemplate from "./MappingFormulaCellTemplate";
import "components/common/grid/styles/PromineoUIGrid.css";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import ContentControlCellComponent from "components/common/ContentControlCellComponent";
import { GetFormulaForContentControl, GetFormulaForNoContentControl } from "shared/utilities/ConfigUtility";

export interface ValueTransformationProps {
  height: number;
  configFieldMappings: FieldMappingResponse[];
  configDirection: ConfigDirection;
  onMappingFormulaUpdate: (data: FieldMappingResponse) => void;
  onValueMapsUpdate: (data: FieldMappingResponse) => void;
  onNewIlapCoreFieldAdd: (data: FieldMappingResponse) => void;
  onIlapCoreFieldDelete: (data: FieldMappingResponse) => void;
  selectedCodeSet?: number | null;
  displayActionColumn?: boolean;
  hideAddCoreFieldButton?: boolean;
  isPreviewChange?: boolean;
  viewOnlyMappingFormula?: boolean;
}
export default function ConfigValueTransformationEditor(
  props: ValueTransformationProps
) {
  const dispatch = useAppDispatch();
  const {
    configFieldMappings,
    onMappingFormulaUpdate,
    onValueMapsUpdate,
    onNewIlapCoreFieldAdd,
    onIlapCoreFieldDelete,
  } = props;

  useEffect(() => {
    if (props.isPreviewChange !== true) {
      displayLoadingPanel();
      dispatch(loadCoreFieldsAsync()).finally(hideLoadingPanel);
    }
  }, []);

  const coreFields = useAppSelector((store) => store.coreFieldData.coreFields);

  const [coreFieldListForPopover, setCoreFieldListForPopover] = useState<
    CoreFieldResponse[]
  >([]);

  const [coreFieldToAdd, setCoreFieldToAdd] =
    useState<CoreFieldResponse | null>(null);

  const [
    displayAddValueMapsWithNoContentControlDialog,
    setDisplayAddValueMapsWithNoContentControlDialog,
  ] = useState(false);

  const [
    displayAddValueMapsWithContentControlDialog,
    setDisplayAddValueMapsWithContentControlDialog,
  ] = useState(false);

  const [displayAddFormulaDialog, setDisplayAddFormulaDialog] = useState(false);

  const [templateFieldMapping, setTemplateFieldMapping] =
    useState<FieldMappingResponse | null>(null);

  const [
    isAddIlapCoreFieldPopoverVisible,
    setIsAddIlapCoreFieldPopoverVisible,
  ] = useState(false);

  const handleAddIlapCoreFieldButtonClicked = () => {
    setIsAddIlapCoreFieldPopoverVisible((prevState) => !prevState);
  };

  useEffect(() => {
    if (coreFields) {
      const newCoreFieldListForPopover: CoreFieldResponse[] = [];

      coreFields.forEach((coreField) => {
        const fieldMapping = configFieldMappings.find(
          (fm) => fm.isCoreFieldMapping && fm.coreFieldId === coreField.id
        );
        if (!fieldMapping) {
          newCoreFieldListForPopover.push(coreField);
        }
      });

      setCoreFieldListForPopover(newCoreFieldListForPopover);
    }
  }, [coreFields, configFieldMappings]);

  useEffect(() => {
    if (templateFieldMapping) {
      const updatedFieldMappingResponse = configFieldMappings.find((fm) => {
        if (fm.id === 0) {
          return fm._key_ === templateFieldMapping._key_;
        } else {
          return fm.id === templateFieldMapping.id;
        }
      });
      if (updatedFieldMappingResponse)
        setTemplateFieldMapping(updatedFieldMappingResponse);
    }
  }, [configFieldMappings, templateFieldMapping]);

  useEffect(() => {
    if (coreFieldToAdd) {
      const newFieldMappingResponse: FieldMappingResponse = {
        _key_: GetNewId(),
        id: 0,
        configId: 0,
        coreFieldId: coreFieldToAdd.id,
        fieldId: 0,
        hasDirectMapping: false,
        mappedConnectorHostFieldId: 0,
        mappedConnectorHostFieldName: "",
        formula: "",
        planningObjectType: coreFieldToAdd.planningObjectType,
        allowContentControl: false,
        allowBlank: false,
        planningObjectTypeText: coreFieldToAdd.planningObjectTypeText,
        valueMaps: [],
        name: coreFieldToAdd.name,
        description: coreFieldToAdd.description,
        dataType: coreFieldToAdd.dataType,
        dataTypeText: coreFieldToAdd.dataTypeText,
        isCoreFieldMapping: true,
        isConfigTemplateFieldMapping: false,
        contentControlLevelText: "",
        values: [],
      };
      onNewIlapCoreFieldAdd(newFieldMappingResponse);
      const newCoreFieldListForPopover = coreFieldListForPopover.filter(
        (coreField) => {
          return coreField.id !== coreFieldToAdd.id;
        }
      );
      setCoreFieldListForPopover(newCoreFieldListForPopover);
      setCoreFieldToAdd(null);
    }
  }, [coreFieldToAdd]);

  const handleAddIlapCoreFieldButtonId = "add-ilap-core-field-button-id";

  const valueMapViewOrAddButtonOnClicked = (data: {
    data: FieldMappingResponse;
  }) => {
    setTemplateFieldMapping(deepCopyObject(data.data));
    data.data.allowContentControl === true
      ? setDisplayAddValueMapsWithContentControlDialog(true)
      : setDisplayAddValueMapsWithNoContentControlDialog(true);
  };

  // Generate formula when formula is empty and value maps are added
  const GetDefaultFormulaForContentControl = () => {
    return GetFormulaForContentControl(templateFieldMapping?.name ?? "", props.configDirection);
  };

  const GetDefaultFormulaForNoContentControl = () => {
    return GetFormulaForNoContentControl(templateFieldMapping?.name ?? "", props.configDirection);
  };

  const toolbarConfig = useMemo<PromineoGridToolbarConfig>(() => {
    return {
      dislplaySearchPanel: true,
      addNewButtonOptions: {
        text: "Add ILAP core field",
        onClick: handleAddIlapCoreFieldButtonClicked,
        buttonId: handleAddIlapCoreFieldButtonId,
        isVisible: props.hideAddCoreFieldButton !== true,
      },
    };
  }, []);

  const onRowDelete = useCallback(
    (evt: { data: FieldMappingResponse }) => {
      if (
        evt.data.isCoreFieldMapping === true &&
        evt.data.isConfigTemplateFieldMapping === false
      ) {
        onIlapCoreFieldDelete(evt.data);
      }
    },
    [onIlapCoreFieldDelete]
  );

  const canDeleteRowFn = useCallback((evt: { data: FieldMappingResponse }) => {
    return (
      evt.data.isCoreFieldMapping === true &&
      evt.data.isConfigTemplateFieldMapping === false
    );
  }, []);

  const rowOpeartionConfig = useMemo(() => {
    return {
      displayDeleteRowOption: {
        visible: true,
        onDelete: onRowDelete,
        canDeleteRowFn: canDeleteRowFn,
      },
      visible: false,
    };
  }, [onRowDelete, canDeleteRowFn]);

  const showFormulaDialogOnValueMapSave = (formula: any) => {
    var showFormulaDialog = true;
    if (formula && formula !== "[VALUE]") showFormulaDialog = false;

    return showFormulaDialog;
  };

  const ContentControlComponent = useCallback(
    (componentProps: { data: { data: FieldMappingResponse } }) => {
      return (
        <ContentControlCellComponent
          fieldMapping={componentProps.data.data}
          isPreviewChange={props.isPreviewChange}
        />
      );
    },
    []
  );

  const MappingFormulaRender = useCallback(
    (data: { data: FieldMappingResponse }) => {
      return props.viewOnlyMappingFormula ? (
        <div className="py-1.5">{data.data.formula}</div>
      ) : (
        <MappingFormulaCellTemplate
          data={data}
          onClick={() => {
            setTemplateFieldMapping(data.data);
            setDisplayAddFormulaDialog(true);
          }}
        />
      );
    },
    []
  );

  return (
    <div>
      <PromineoViewGrid
        dataSource={configFieldMappings}
        className="promineo-ui-grid"
        toolbarConfig={toolbarConfig}
        rowOperationConfig={rowOpeartionConfig}
        height={props.height}
        noDataText="Fields using value transformation will appear here!"
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <Column
          caption={"Planning object type"}
          dataField={"planningObjectTypeText"}
          alignment="left"
        />
        <Column caption={"ILAP term"} dataField={"name"} alignment="left" />
        <Column
          caption={"Description"}
          dataField={"description"}
          alignment="left"
        />
        <Column
          caption={"Data type"}
          dataField={"dataTypeText"}
          alignment="left"
        />
        <Column
          caption={"Content Control"}
          alignment="left"
          cellComponent={ContentControlComponent}
        />
        <Column
          caption={"Mapping formula"}
          alignment="left"
          cssClass="add-left-border custom-control-padding"
          cellRender={MappingFormulaRender}
        />
        <Column
          caption={"Host field"}
          dataField={"mappedConnectorHostFieldName"}
          alignment="left"
        />
        <Column
          caption={"Value map"}
          alignment="left"
          cssClass="add-left-border custom-control-padding"
          cellRender={(data: { data: FieldMappingResponse }) => {
            return data.data.valueMaps.filter((m) => m.value).length === 0 ? (
              <AddButtonCellTemplate
                onClick={() => {
                  valueMapViewOrAddButtonOnClicked(data);
                }}
              />
            ) : (
              <ViewValueMapButtonCellTemplate
                onClick={() => {
                  valueMapViewOrAddButtonOnClicked(data);
                }}
              />
            );
          }}
        />
        <Column
          caption={"Action"}
          alignment="left"
          visible={props.displayActionColumn === true}
        />
      </PromineoViewGrid>
      {isAddIlapCoreFieldPopoverVisible && (
        <AddIlapCoreFieldPopOverGrid
          dataSource={coreFieldListForPopover}
          target={`#${handleAddIlapCoreFieldButtonId}`}
          visible={isAddIlapCoreFieldPopoverVisible}
          onHiding={() => {
            setIsAddIlapCoreFieldPopoverVisible(false);
          }}
          setCoreFieldToAdd={setCoreFieldToAdd}
        />
      )}
      {displayAddFormulaDialog && templateFieldMapping && (
        <AddMappingFormulaDialog
          configDirection={props.configDirection}
          fieldMapping={templateFieldMapping}
          onCancel={() => {
            setDisplayAddFormulaDialog(false);
          }}
          onSave={onMappingFormulaUpdate}
          selectedCodeSet={props.selectedCodeSet}
        />
      )}

      {displayAddValueMapsWithContentControlDialog && templateFieldMapping && (
        <AddValueMapsWithContentControlDialog
          fieldMapping={templateFieldMapping}
          isPreviewChange={props.isPreviewChange}
          onCancel={() => setDisplayAddValueMapsWithContentControlDialog(false)}
          onSave={(data: FieldMappingResponse) => {
            if (showFormulaDialogOnValueMapSave(data.formula) === true) {
              data.formula = GetDefaultFormulaForContentControl();
              onValueMapsUpdate(data);
              setTemplateFieldMapping(data);
              setDisplayAddFormulaDialog(true);
            } else {
              onValueMapsUpdate(data);
            }
          }}
        />
      )}

      {displayAddValueMapsWithNoContentControlDialog &&
        templateFieldMapping && (
          <AddValueMapsWithNoContentControlDialog
            fieldMapping={templateFieldMapping}
            onCancel={() =>
              setDisplayAddValueMapsWithNoContentControlDialog(false)
            }
            onSave={(data: FieldMappingResponse) => {
              if (showFormulaDialogOnValueMapSave(data.formula) === true) {
                data.formula = GetDefaultFormulaForNoContentControl();
                onValueMapsUpdate(data);
                setTemplateFieldMapping(data);
                setDisplayAddFormulaDialog(true);
              } else {
                onValueMapsUpdate(data);
              }
            }}
          />
        )}
    </div>
  );
}
