import { globalExceptions } from "apis/ApiConstants";
import { getTokenAsync } from "authentication/AuthenticationManager";
import {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import ErrorResponse from "interfaces/response/ErrorResponse";
import { getApiUrl } from "shared/utilities/ConfigUtility";
import {
  handleNoTenantError,
  handleUserDisabledError,
} from "store/slices/ErrorSlice";
import { parseErrorResponse } from "./ErrorParser";

export function WithInterceptors(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.response.use(
    handleSuccessfulRequest,
    handleFailedRequest
  );
  return axiosInstance;
}

export function WithAuthenticationInterceptors(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      if (
        config.baseURL === getApiUrl() &&
        !(config && config.headers && config.headers.Authorization)
      ) {
        const token = await getTokenAsync();
        if (token && config && config.headers) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
      return config;
    }
  );

  axiosInstance.interceptors.response.use(
    handleSuccessfulRequest,
    handleFailedRequest
  );
  return axiosInstance;
}

function handleSuccessfulRequest(response: AxiosResponse<any>) {
  return response;
}

function handleFailedRequest(error: AxiosError<any>) {
  const parsedError = parseErrorResponse(error);
  return Promise.reject(parsedError);
}

export const errorMiddleware = (store: any) => (next: any) => (action: any) => {
  if (action.error) {
    const errorResponse = action.error as ErrorResponse;
    if (
      errorResponse.exceptionType === globalExceptions.TenantNotFoundException
    ) {
      store.dispatch(handleNoTenantError(errorResponse.message));
    } else if (
      errorResponse.exceptionType === globalExceptions.UserIsDisabledException
    ) {
      store.dispatch(handleUserDisabledError(errorResponse.message));
    }
  }

  return next(action);
};
