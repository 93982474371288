import CrossIcon from "components/icons/CrossIcon";

interface Props{
  onClose: () => void;
}

export default function IEADetailDialogHeader(props: Props) {
  return (
    <div>
      <div className="flex justify-between w-full">
        <div className="font-Poppins font-bold leading-[21px] text-[14px]">
          IEA details
        </div>
        <div onClick={props.onClose} className="cursor-pointer">
          <CrossIcon />
        </div>
      </div>
    </div>
  );
}