import {
  getHostSystemConfigurationDefinitionAsync,
  getStructureToExportCandidatesAsync,
  getWbsStructureCandidatesAsync,
} from "apis/HostSystemApi";
import HostSystemDefinitionResponse from "interfaces/response/HostSystemDefinitionResponse";
import { ExchangeRoleEnum } from "shared/enums/feature/ExchangeRoleEnum";
import { HostSystem } from "shared/enums/feature/HostSystem";
import {
  StructuresToExportCandidatesListByConnectorAndCodeSet,
  WbsStructureCandidatesListByConnectorAndCodeSet,
  getHostSystemConfigDefinitionDictKey,
} from "store/slices/HostSystemSlice";
import { RootState } from "store/store";
import { createAppAsyncThunk } from "store/ThunkUtility";

export const loadHostSystemConfigurationDefinitionAsync = createAppAsyncThunk<
  HostSystemDefinitionResponse[],
  { hostSystem: HostSystem; exchangeRole: ExchangeRoleEnum },
  { state: RootState }
>(
  "hostSystem/loadHostSystemConfigurationDefinition",
  async (request, thunkApi) => {
    const state = thunkApi.getState();
    const dictKey = getHostSystemConfigDefinitionDictKey(
      request.hostSystem,
      request.exchangeRole
    );
    const dictValue =
      state.hostSystemData.hostSystemConfigurationDefinitionDict[dictKey];
    if (dictValue) {
      return dictValue;
    } else {
      return await getHostSystemConfigurationDefinitionAsync(
        request.hostSystem,
        request.exchangeRole
      );
    }
  }
);

export const loadWbsStructureCandidatesAsync = createAppAsyncThunk<
  any,
  { codeSetId: number; connectorId: number },
  { state: RootState }
>("hostSystem/loadWbsStructureCandidatesAsync", async (request, thunkAPI) => {
  const state = thunkAPI.getState();

  const existingWbsStructureCandidateIndex =
    state.hostSystemData.wbsStructureCandidatesListByConnectorAndCodeSet.findIndex(
      (wbsCandidates: WbsStructureCandidatesListByConnectorAndCodeSet) =>
        wbsCandidates.codeSetId === request.codeSetId &&
        wbsCandidates.connectorId === request.connectorId
    );

  if (existingWbsStructureCandidateIndex > -1) {
    return state.hostSystemData.wbsStructureCandidatesListByConnectorAndCodeSet[
      existingWbsStructureCandidateIndex
    ].wbsStructureCandidates;
  } else {
    return await getWbsStructureCandidatesAsync(
      request.connectorId,
      request.codeSetId
    );
  }
});

export const loadStructureToExportCandidatesAsync = createAppAsyncThunk<
  any,
  { codeSetId: number; connectorId: number },
  { state: RootState }
>(
  "hostSystem/loadStructureToExportCandidatesAsync",
  async (request, thunkAPI) => {
    const state = thunkAPI.getState();

    const existingStructuresToExportCandidateIndex =
      state.hostSystemData.structuresToExportCandidatesListByConnectorAndCodeSet.findIndex(
        (candidates: StructuresToExportCandidatesListByConnectorAndCodeSet) =>
          candidates.codeSetId === request.codeSetId &&
          candidates.connectorId === request.connectorId
      );

    if (existingStructuresToExportCandidateIndex > -1) {
      return state.hostSystemData
        .structuresToExportCandidatesListByConnectorAndCodeSet[
        existingStructuresToExportCandidateIndex
      ].structuresToExportCandidates;
    } else {
      return await getStructureToExportCandidatesAsync(
        request.connectorId,
        request.codeSetId
      );
    }
  }
);
