import {
  getFieldDetailsWithContentControlValuesByTemplateIdAsync,
  getFieldsAsync,
} from "apis/FieldApi";
import {
  getTemplateFieldByTemplateFieldIdAsync,
  getTemplateFieldByTemplateIdAndFieldIdAsync,
  manageTemplateFieldAsync,
} from "apis/TemplateFieldApi";
import FieldManageRequest from "interfaces/request/FieldManageRequest";
import { createAppAsyncThunk } from "store/ThunkUtility";

export const loadFieldsAsync = createAppAsyncThunk(
  "templates/loadFields",
  async () => {
    return await getFieldsAsync();
  }
);

export const loadTemplateFieldByTemplateFieldIdAsync = createAppAsyncThunk(
  "templates/loadTemplateFieldsByTemplateFieldId",
  async (templateFieldId: number) => {
    return await getTemplateFieldByTemplateFieldIdAsync(templateFieldId);
  }
);

export const loadTemplateFieldByTemplateIdAndFieldIdAsync = createAppAsyncThunk<
  any,
  { templateId: number; fieldId: number }
>("templates/loadTemplateFieldByTemplateIdAndFieldId", async (request) => {
  return await getTemplateFieldByTemplateIdAndFieldIdAsync(
    request.templateId,
    request.fieldId
  );
});

export const updateTemplateFieldAsync = createAppAsyncThunk<
  any,
  { templateFieldId: number; manageRequest: FieldManageRequest }
>("templates/manageTemplateField", async (request) => {
  return await manageTemplateFieldAsync(
    request.templateFieldId,
    request.manageRequest
  );
});

export const loadFieldDetailsWithContentControlValuesByTemplateIdAsync =
  createAppAsyncThunk(
    "templates/loadFieldDetailsWithContentControlValuesByTemplateId",
    async (templateId: number) => {
      return await getFieldDetailsWithContentControlValuesByTemplateIdAsync(
        templateId
      );
    }
  );
