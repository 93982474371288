import { addLabelsAsync, getLabelsAsync } from "apis/LabelApi";
import LabelRequest from "interfaces/request/LabelRequest";
import { SupportedLabelEntity } from "shared/enums/feature/SupportedLabelEntity";
import { createAppAsyncThunk } from "store/ThunkUtility";

export const loadLabelsAsync = createAppAsyncThunk(
  "labels/loadLabelsForTemplate",
  async (supportedLabelEntity: SupportedLabelEntity) => {
    return await getLabelsAsync(supportedLabelEntity);
  }
);

export const addNewLabelsAsync = createAppAsyncThunk(
  "labels/addNewLabel",
  async (labelRequest: LabelRequest) => {
    return await addLabelsAsync(labelRequest);
  }
);
